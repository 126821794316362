import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import axios from 'axios';
import cookies from 'js-cookie';
import envs from './env';

import { UserContext } from './UserContext';

import Navigation from './components/Navigation/Navigation';
import Contact from './components/Contact/Contact';
import Charity from './components/Home/Charity';
import Events from './components/Events/Events';
import Item from './components/Item/Item';
import Footer from './components/Footer/Footer';

import Login, {LoginTwoFA} from './components/Register/Login';
import Signup from './components/Register/Signup';
import ForgotPasswordForm from './components/Register/ForgotPassword/ForgotPasswordForm';
import ChangePassword from './components/Register/ForgotPassword/ChangePassword';

import Dashboard from './components/Dashboard/Dashboard.js';
import User from './components/UsersPage/UsersPage';
import Checkout from './components/Checkout/Checkout';
// import SalesPage from "./components/Sales/salesPage.component";
import SalesPage from "./components/Sales/salesPage";
import Confirmation from './components/Confirmation/Confirmation';

import './App.css';
const App = (props) => {
  const [user, setUser] = useContext(UserContext);
  const [dashNavItem, setDashNavItems] = React.useState([]);

  // Check if engineer
  let checkRole = (user) => {
    let roleStats = {
      "Engineer" : 100,
      "Owner": 10,
      "Admin": 1,
      "User": 0
    }
    let role = "User";
    user.Roles.forEach(e => {
      let tempRole = e.RoleStatus;
      if (roleStats[role] < roleStats[tempRole]) {
        role = tempRole;
      }
    })
    return role;
  }

  const getAccountInfo = async (tok) => {
    const authHead = `Bearer ${tok ? tok : ""}`;

    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: authHead,
      },
    };

    const Link = `${envs.url}accounts?currentUserOnly=1`;
    try {
      const response = await axios.get(Link, headers);
      // console.log("USER", response.data[0]);
      setUser({
        ...response.data[0],
        Role: checkRole(response.data[0]),
        Token: cookies.get("Token"),
        LoggedIn: true
      });
    } catch (err) {console.log(err)}
  };

  const updateUser = async () => {
    if (cookies.get("Token")) //this will stop the error from showing in console
      getAccountInfo(cookies.get("Token"));
  }

  useEffect(() => {
    updateUser();
  }, [])

  return (
    <Router>
      <Navigation DashNav={dashNavItem}/>
      <div className="App">
        <Switch>
          
          <Route path="/" exact component={Events} />
          <Route path="/contact" component={Contact} />
          <Route path="/checkout" component={Checkout} />
          <Route path="/confirmation" component={Confirmation} />
          <Route path="/event/:id" component={Charity} />
          <Route path="/login" component={() => <Login />}/>
          <Route path="/login2fa" component={LoginTwoFA}/>
          <Route path="/signup" component={Signup} />
          <Route path="/item/:id" component={Item} />
          <Route path="/user/:id" component={User} />
          <Route path="/learnmore" component={SalesPage} />
          <Route exact path="/dashboard" render={(props) => (<Dashboard dashNavItems={setDashNavItems}/>)} />
          <Route path="/forgotpw" component={ForgotPasswordForm} />
          <Route path="/changepw/email=:email&token=:token" render={(props) => <ChangePassword {...props} />} />
          <Route path="/*" component={Events}></Route>

        </Switch>
      </div>
      <Footer />
    </Router>
  );

};

export default App;
