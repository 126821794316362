import styled from 'styled-components';
import { Image as Img, Card as BSCard } from 'react-bootstrap';

export const Card = styled(BSCard)`
  margin: 1rem;
  width: 300px;
`;

export const Image = styled(Img)`
  margin-bottom: 1rem;
  height: 245px;
  width: 100%;
`;
