import React, { useEffect, useContext } from 'react';
import moment from 'moment';
import { Table, Card, ListGroup } from 'react-bootstrap';

import { UserContext } from '../../../UserContext';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { populateUsersTable } from '../../../redux/actions/dashboardActions';

const UserReport = (props) => {
  const dispatch = useDispatch();
  const dashboard = useSelector((state) => state.dashboard);

  const [user, setUser] = useContext(UserContext);

  useEffect(() => {
    console.log(user);
    dispatch(populateUsersTable(dashboard.currentEvent.ID, user.Token));
  }, []);

  useEffect(() => {
    dispatch(populateUsersTable(dashboard.currentEvent.ID, user.Token));
  }, [dashboard.currentEvent]);

  return (
    <div style={{ overflow: 'auto' }}>
      <div className="user-report-cont" style={{ margin: '1rem' }}>
        <Card
          style={{
            boxShadow: '0 0 5px rgba(0,0,255,0.5)',
            border: '1px solid rgba(0,0,255,0.1)',
            marginBottom: '1rem',
          }}
        >
          <Card.Header>User Count</Card.Header>
          <ListGroup variant="flush">
            <ListGroup.Item>
              {dashboard.usersTableData.userCount}
            </ListGroup.Item>
          </ListGroup>
        </Card>

        <Table responsive style={{ overflow: 'auto auto' }}>
          <thead>
            <tr>
              <th>ID</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Cell</th>
              <th>Email</th>
              <th>Signed Up</th>
            </tr>
          </thead>
          <tbody>
            {dashboard.usersTableData.users &&
              dashboard.usersTableData.users.map((user) => (
                <tr key={user.ID}>
                  <td>{user.ID}</td>
                  <td>{user.FirstName}</td>
                  <td>{user.LastName}</td>
                  <td>{user.Cell}</td>
                  <td>{user.Email}</td>
                  <td>{moment(user.CreateDate).format('LLL')}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default UserReport;
