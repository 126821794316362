import styled from 'styled-components';
import Carousel from 'react-bootstrap/Carousel';

export const StyledCarousel = styled(Carousel)`
 width: 100%;
 height: 100%;
 padding: 15px;
`

export const CarouselItem = styled(Carousel.Item)`
`

export const CarouselItemImg = styled.div`
  height: 220px;
  background: url(${(props) => props.src});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`