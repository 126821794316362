import styled from "styled-components";

export const ImagePreview = styled.div`
  width: 100px;
  height: 100px;
  background: url(${props => props.src});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  margin: .5rem .25rem 0;
  position: relative;
`

export const DeleteButton = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  width: fit-content;
  background-color: darkgray;
  padding: .2rem .5rem;
  border-radius: 50%;
  font-weight: bold;
  font-size: .75rem;
  cursor: pointer;
  &:hover {
    filter: brightness(.9);
  }
`

export const SuccessMark = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  cursor: pointer;
  color: green;
  &:hover {
    color: tomato;
  }
`

export const UploadButton = styled.button`
  background-color: gainsboro;
  border:none;
  padding: .5rem .75rem;
  border-radius: 3px;
`