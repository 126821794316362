import React from 'react';

// styles
import {
  EventBannerContainerFull,
  EventBannerImageFull,
  EventBannerContainerSplit,
  EventBannerDescriptionSplit,
  EventBannerDescriptionHeader,
  EventBannerDescriptionP,
  ImageContainerSplit,
  ImageSplit
} from './EventBannerStyles';

export default function EventBanner({ event, full = false }) {
  return (
    <>
      {full ? (
        <>
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
              paddingTop: '.5rem',
            }}
          >
            <h2 style={{ margin: '0' }}>{event.Name}</h2>
          </div>
          <EventBannerContainerFull>
            <EventBannerImageFull src={event.Images[0]}></EventBannerImageFull>
          </EventBannerContainerFull>
        </>
      ) : (
        // SPLIT BANNER (Image + Description)
        <EventBannerContainerSplit>
          <ImageContainerSplit>
            <ImageSplit src={event.Images} />
          </ImageContainerSplit>
          <EventBannerDescriptionSplit>
            <EventBannerDescriptionHeader>
              {event.Name || 'Loading...'}
            </EventBannerDescriptionHeader>
            <EventBannerDescriptionP>
              {event.Description || 'Loading...'}
            </EventBannerDescriptionP>
          </EventBannerDescriptionSplit>
        </EventBannerContainerSplit>
      )}
    </>
  );
}
