import React from "react";

import "bootstrap/dist/css/bootstrap-grid.min.css";
import "./salesPage.css";

const pid =
    "AdH9_PDz6fDE-3Ne1uDXxnciPqpT5E4Oa1hrWBSfjZGxiEtJtdeL69xuHmWWdJ5mTPnkcpMl3u5imlOs";
const SalesPage = () => (
    <div className="container p-3 my-3 bg-light text-black">
        <h1 className="text-center">Learn More About RAA Live!</h1>
        <h6 className="text-center">
            <i>A platform built to benefit organizations around the world</i>
        </h6>
        <br />
        <br />
        <p className="text-align-left">
            Raffles and Auctions Live (raalive.com) is a premier platform for
            hosting your Auctions online at a price far below what many other
            online auction platforms charge. Since most auctions are held for
            charity purposes, we believe that the charity or organization should
            retain most of the proceeds, not the platform!
        </p>
        <p className="text-align-left">
            <strong>Benefits of using RAA Live include:</strong>
            <br />
            <li>
                Easy to use interface for the organization administrator for
                quick and easy set up.
            </li>
            <li>Instant turnaround to get your event live.</li>
            <li>Phone support throughout your event.</li>
            <li>Quick Bid buttons with minimum raise amounts built in.</li>
            <li>An Auto Bid feature to help get more bids.</li>
            <li>
                Text messages to the users to let them know if they have been
                outbid.
            </li>
            <li>
                Buy It Now button option with optional payment required within a
                specified time.
            </li>
            <li>
                An administrator dashboard with reports including Item status
                and users.
            </li>
            <li>
                My Auctions page for users to review all their bids and items
                they are bidding on or watching.
            </li>
            <li>
                An easy checkout and payment interface using the power of
                PayPal.
            </li>
            <li>
                Payments direct from the purchaser to your PayPal account (If
                you don’t have a PayPal Account, we can help you get one set up
                for free!).
            </li>
        </p>
        <br />
        <p className="text-align-left">
            Additional features coming soon including Online Raffles where you
            can sell tickets online, draw winners virtually and notify winners
            automatically!
        </p>
        <br />
        <p className="text-align-left">
            <strong>So, what does it cost?</strong> How about less than $10 a
            month! That’s right! For $119 a year, you have access to use the
            platform for Auctions as often as you want including up to 5,000
            text messages a year. (Additional text messages are available for
            only 1 cent each if you use your 5,000) We don’t like small print
            and bet you don’t either, so it is just that simple!
        </p>
        <br />
        <p className="text-align-left">
            Sign up now and we’ll have your auction up and running within 7
            days, or
            <a href="/contact"> contact us</a> by{" "}
            <a href="mailto:tony.mitchell@pingsoftwareusa.com">email</a> or at
            313-949-8395 if you have more questions.
            <br />
            <div style={{ paddingTop: "50px", width: "25%" }}>
                <div id="paypal-button-container"></div>
            </div>
        </p>
        <br />
        <p className="text-align-left">
            <strong>
                RAALive.com (Raffles and Auctions Live) is a platform developed
                by Ping Software USA &copy; {new Date().getFullYear()}{" "}
                <a href="http://www.pingsoftwareusa.com" target="new">
                    {" "}
                    Ping Software USA{" "}
                </a>
            </strong>
        </p>
    </div>
);

document.write(
    '\
    <script src="https://www.paypal.com/sdk/js?client-id=' +
        pid +
        '&currency=USD" data-sdk-integration-source="button-factory"></script>\
    <script>\
      paypal.Buttons({\
          style: {\
              shape: "rect",\
              color: "gold",\
              layout: "vertical",\
              label: "paypal",\
    \
          },\
          createOrder: function(data, actions) {\
              return actions.order.create({\
                  purchase_units: [{\
                      amount: {\
                          value: "1"\
                      }\
                  }]\
              });\
          },\
          onApprove: function(data, actions) {\
              return actions.order.capture().then(function(details) {\
                  alert("Transaction completed by " + details.payer.name.given_name + "!");\
              });\
          }\
      }).render("#paypal-button-container");\
    </script>'
);

export default SalesPage;
