import React, { useCallback, useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import {
  Table,
  Spinner,
  Button,
  Modal,
  OverlayTrigger,
  Tooltip,
  Toast,
  Badge,
  CardDeck,
  Card,
  ListGroup,
} from "react-bootstrap";

import _ from "lodash";

// import "./Events.css";
import { UserContext } from "../../../UserContext";
import EditItemModal from '../EditItemModal/EditItemModal';
import './Table.css';
import envs from "../../../env";

const ItemSummaryCards = (props) => {
  return(
    <CardDeck id="cardDeck" style={{marginBottom: "1rem"}}>
        <Card className="cards-style">
          <Card.Header>Total Bids</Card.Header>
          <ListGroup variant="flush">
            <ListGroup.Item>$ {props.expectedRevenue}</ListGroup.Item>
          </ListGroup>
        </Card>

        <Card className="cards-style">
          <Card.Header>Total Paid</Card.Header>
          <ListGroup variant="flush">
            <ListGroup.Item>$ {props.actualRevenue}</ListGroup.Item>
          </ListGroup>
        </Card>

        <Card className="cards-style">
          <Card.Header>Item With Bids</Card.Header>
          <ListGroup variant="flush">
            <ListGroup.Item>{props.activeItems}</ListGroup.Item>
          </ListGroup>
        </Card>

        <Card className="cards-style">
          <Card.Header>Total Items</Card.Header>
          <ListGroup variant="flush">
            <ListGroup.Item>{props.totalItems}</ListGroup.Item>
          </ListGroup>
        </Card>
      </CardDeck>
  )
};

const ItemTable = (props) => {
  return(
    <>
    <h4>{props.eventName} Items</h4>
    <Table responsive>
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Retail Value</th>
          <th>Starting Bid</th>
          <th>Status</th>
          <th>Current Bidder</th>
          <th>Bidder Cell</th>
          <th>Current Bid</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>{props.itemTables(props.items)}</tbody>
    </Table>
    </>
  )
};



const MainTable = (props) => {
  // DEV
  // Current user role
  const [role, setRole] = useState(Cookies.get("xyz"));

  // const [item, setEvents] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [chosenItemEdit, setChosenItemEdit] = useState({});

  const [items, setItems] = useState([]);
  const [ready, setReady] = useState(false);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  
  const [tableReady, setTableReady] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const [showToast2, setShowToast2] = useState(false);
  const [chosenItem, setChosenItem] = useState({});
  const [chosenItem2, setChosenItem2] = useState({});
  
  const [itemDeleted, setItemDeleted] = useState(false);
  const [itemComplete, setItemComplete] = useState(false);
  const [itemEdited, setItemEdited] = useState(false);

  const [expectedRevenue, setExpectedRevenue] = useState(0);
  const [actualRevenue, setActualRevenue] = useState(0);
  const [activeItems, setActiveItems] = useState(0);
  const [totalItems, setTotalItems] = useState(0);

  // THIS IS FOR THE DROPDOWN
  const [selectedCharity, setSelectedCharity] = useState(props.currChar);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(props.currEvent);
  
  const [itemChanged, setItemChanged] = useState(false);
  
  const [user, setUser] = useContext(UserContext);

  useEffect(() => {
    setSelectedCharity(props.currChar);
    setSelectedEvent(props.currEvent);
  }, [props.currChar, props.currEvent]);
  
  useEffect(() => {
    getItems();
  }, [itemDeleted, itemComplete, selectedEvent, itemEdited, itemChanged]);

  const toggleShow = () => setShowToast(!showToast);
  const toggleShow2 = () => setShowToast2(!showToast2);

  // DELETE ITEM MESSAGE
  const showMsg = (createdItem) => {
    return (
      <Toast
        show={showToast}
        onClose={toggleShow}
        variant="success"
        animation={true}
        style={{
          width: "max-content",
          position: "fixed",
          top: "70px",
          right: "10px",
          zIndex: "99",
          color: "#155724",
          backgroundColor: "#d4edda",
          borderColor: "#c3e6cb",
        }}
      >
        <Toast.Header
          style={{
            color: "#155724",
            backgroundColor: "#f1fff5",
            borderColor: "#c3e6cb",
          }}
        >
          <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
          <strong className="mr-auto">Item Deletion Successful</strong>
        </Toast.Header>
        <Toast.Body style={{ color: "rgb(21, 87, 36)", paddingLeft: "1.4rem" }}>
          Your item has successfully been deleted!
        </Toast.Body>
      </Toast>
    );
  };
  // ITEM COMPLETE MESSAGE
  const showMsg2 = (createdItem) => {
    return (
      <Toast
        show={showToast2}
        onClose={toggleShow2}
        variant="success"
        animation={true}
        style={{
          width: "max-content",
          position: "fixed",
          top: "70px",
          right: "10px",
          zIndex: "99",
          color: "#155724",
          backgroundColor: "#d4edda",
          borderColor: "#c3e6cb",
        }}
      >
        <Toast.Header
          style={{
            color: "#155724",
            backgroundColor: "#f1fff5",
            borderColor: "#c3e6cb",
          }}
        >
          <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
          <strong className="mr-auto">Item Marked Complete</strong>
        </Toast.Header>
        <Toast.Body style={{ color: "rgb(21, 87, 36)", paddingLeft: "1.4rem" }}>
          Your item has been completed!
        </Toast.Body>
      </Toast>
    );
  };

  const getItems = () => {
    const authHead = `Bearer ${
      user.Token ? user.Token : ""
    }`;
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: authHead,
      },
    };

    const link = `${envs.url}items?EventID=${selectedEvent.ID}`;

    axios.get(link, headers)
      .then((res) => {
        let newData = _.sortBy(res.data, function (obj) {
          return parseInt(obj.Number, 10);
        });
        calculateRevenue(res.data);
        setItems(newData);
        setItemChanged(true);
        setTimeout(() => setReady(true), 500);
      });
  };

  const calculateRevenue = (itms) => {
    let expected = 0;
    let actual = 0;
    let active = 0;
    let total = 0;

    for (let index in itms) {
      let currentItem = itms[index];
      total++;

      if (currentItem.BidCurrent && currentItem.BidCurrent.Amount) {
        expected += currentItem.BidCurrent.Amount;

        if (
          currentItem.Status === "Paid" ||
          currentItem.Status === "Complete"
        ) {
          actual += currentItem.BidCurrent.Amount;
        } else if (currentItem.Status === "Live") {
          active++;
        }
      }
    }

    setTotalItems(total);
    setActiveItems(active);
    setActualRevenue(actual);
    setExpectedRevenue(expected);
  };

  const item_update_status = (data, status) => {
    if (status === "Deleted") {
      setItemDeleted(false);
      setShow(false);
      setShowToast(false);
    } else if (status === "Complete") {
      setItemComplete(false);
      setShow2(false);
      setShowToast2(false);
    }

    const authHead = `Bearer ${
      user.Token ? user.Token : ""
    }`;

    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: authHead,
      },
    };

    const Link = `${envs.url}items`;

    const payload = {
      EventID: props.currEvent.ID,
      Name: data.Name,
      Description: data.Description,
      Number: data.Number,
      Images: data.Images,
      Value: data.Value,
      Type: 0,
      Sold: false,
      BidStart: data.BidStart,
      BidIncrease: data.BidIncrease,
      BuyItNow: data.BuyItNow,
      BidCurrent: data.BidCurrent,
      CreateDate: data.CreateDate,
      UpdateDate: data.UpdateDate,
      BidEndDate: data.BidEndDate,
      BidHistory: data.BidHistory,
      ID: data.ID,
      Status: status,
    };

    console.log(Link, payload, headers);
    axios
      .post(Link, payload, headers)
      .then((res) => {
        console.log("Item Updated: ", res);

        if (status === "Deleted") {
          setShowToast(true);
          setItemDeleted(true);
        } else if (status === "Complete") {
          setShowToast2(true);
          setItemComplete(true);
        }
      })
      .catch((err) => console.log(err.response.data.Message));
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Edit this Item
    </Tooltip>
  );

  const renderTooltip2 = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Delete this Item
    </Tooltip>
  );

  const renderTooltip3 = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Mark Item Complete
    </Tooltip>
  );

  const renderItems = (item) => {
    return (
      <tr key={item.ID}>
        <td>{item.Number}</td>
        <td>
          <Link to={{ pathname: `/item/${item.ID}` }}>{item.Name}</Link>
        </td>
        <td>{item.Value}</td>
        <td>{"$" + item.BidStart}</td>
        <td>{item.Status}</td>
        <td>
          {item.BidCurrent.FirstName
            ? item.BidCurrent.FirstName + " " + item.BidCurrent.LastName
            : item.BidCurrent.Name
            ? item.BidCurrent.Name
            : "None"}
        </td>
        <td>{item.BidCurrent.Cell ? item.BidCurrent.Cell : "None"}</td>
        <td>
          {item.BidCurrent.Amount ? "$" + item.BidCurrent.Amount : "None"}
        </td>
        <td style={{ display: "grid", "grid-auto-flow": "column" }}>
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
          >
            <Button 
              variant="warning"
              onClick={() => {
                setShowEdit(true);
                setChosenItemEdit(item);
              }}>
              <i class="far fa-edit"></i>
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip3}
          >
            <Button
              variant="info"
              onClick={() => {
                setShow2(!show);
                setChosenItem2(item);
              }}
              style={{ marginLeft: "1rem" }}
            >
              <i class="fa fa-handshake-o"></i>
            </Button>
          </OverlayTrigger>

          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip2}
          >
            <Button
              variant="danger"
              onClick={() => {
                setShow(!show);
                setChosenItem(item);
              }}
              style={{ marginLeft: "1rem" }}
            >
              <i class="far fa-trash-alt"></i>
            </Button>
          </OverlayTrigger>
        </td>
      </tr>
    );
  };

  const item_tables = useCallback(
    (items) => {
      if (tableReady) {
        return items.map(renderItems);
      } else {
        return null;
      }
    },
    [tableReady, getItems, items]
  );

  const handleClose = () => setShow(false);
  const handleClose2 = () => setShow2(false);
  const handleCloseEdit = () => setShowEdit(false); // EDIT ITEM MODAL

  return (
    <div className="Event-Container" style={{ "overflow-y": "auto" }}>
      {showToast && chosenItem ? showMsg(chosenItem) : <></>}
      {showToast2 && chosenItem2 ? showMsg2(chosenItem2) : <></>}

      <hr></hr>
      <ItemSummaryCards 
        expectedRevenue={expectedRevenue}
        actualRevenue={actualRevenue}
        activeItems={activeItems}
        totalItems={totalItems} />

      {ready ? (
        <ItemTable eventName={selectedEvent.Name} itemTables={item_tables} items={items} />
      ) : (
        <div
          className="spin-cont2"
          style={{
            margin: "5rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner animation="grow"></Spinner>
        </div>
      )}
      {show && chosenItem ? (
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton variant="danger">
            <Modal.Title>Item Delete Confirmation!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you would like to delete the item:{" "}
            <Badge style={{ fontSize: "1.15rem" }} variant="danger">
              {chosenItem.Name}
            </Badge>
            ?<br></br>
            It will be gone forever!
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="danger"
              onClick={() => item_update_status(chosenItem, "Deleted")}
            >
              Confirm Delete
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        <></>
      )}
      {show2 && chosenItem2 ? (
        <Modal
          show={show2}
          onHide={handleClose2}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton variant="danger">
            <Modal.Title>Item Complete Confirmation!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you would like to complete the item:{" "}
            <Badge style={{ fontSize: "1.15rem" }} variant="info">
              {chosenItem2.Name}
            </Badge>
            ?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
            <Button
              variant="info"
              onClick={() => item_update_status(chosenItem2, "Complete")}
            >
              Confirm Completion
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        <></>
      )}

      {
        showEdit && chosenItemEdit ?
        (
          <EditItemModal
            setItemChanged={setItemChanged}
            show={showEdit}
            handleClose={handleCloseEdit}
            events={events}
            item={chosenItemEdit}
            currEvent={selectedEvent}
            closeModal={() => setShowEdit(false)}
            showEdited={() => {
              setItemEdited(true);
              setTimeout(() => setItemEdited(false), 0);
            }} />
        ) :
        (
          <></>
        )
      }

      <br />
    </div>
  );
};

export default MainTable;
