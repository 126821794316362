import React, { useState, useContext, useEffect } from 'react';
import { Button, Form, Col, Row, Toast } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import DateTimePicker from 'react-datetime-picker';
import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faFileArrowUp,
} from '@fortawesome/free-solid-svg-icons';

// custom components
import RAAToast from '../../Toast/Toast';

// redux | context
import { UserContext } from '../../../UserContext';
import { useDispatch, useSelector } from 'react-redux';
import {
  addImage,
  removeImage,
  clearItemImages,
  uploadImage,
} from '../../../redux/actions/itemImagesActions';

// styles
import './Create.css';
import {
  ImagePreview,
  DeleteButton,
  SuccessMark,
  UploadButton,
} from './ImageUploadStyles';

let envs = require('../../../env');

const Create = (props) => {
  const dispatch = useDispatch();
  const itemImages = useSelector((state) => state.itemImages);

  const [user, setUser] = useContext(UserContext);
  // const [CharityId, setCharityId] = useState("pFnC_eMsz");
  const [startDate, setStartDate] = useState(props.currEvent.EventStart);
  const [endDate, setEndDate] = useState(props.currEvent.EventEnd);
  const [createdItem, setCreatedItem] = useState([]);

  const [showToast, setShowToast] = useState(false);
  const [savedItem, setSavedItem] = useState(false);

  const [showErrorToast, setShowErrorToast] = useState(false);

  // EVENTS ARRAY
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(props.currEvent);

  useEffect(() => {
    let start = new Date(props.currEvent.EventStart);
    setSelectedEvent(props.currEvent);
    setStartDate(props.currEvent.EventStart);
    setEndDate(props.currEvent.EventEnd);
  }, [props, props.currEvent]);

  const toggleShow = () => setShowToast(!showToast);
  const closeErrorToast = () => setShowErrorToast(false);

  const showMsg = (createdItem) => {
    return (
      <Toast
        show={showToast}
        onClose={toggleShow}
        variant="success"
        animation={true}
        style={{
          width: 'max-content',
          position: 'fixed',
          top: '70px',
          right: '10px',
          zIndex: '99',
          color: '#155724',
          backgroundColor: '#d4edda',
          borderColor: '#c3e6cb',
        }}
      >
        <Toast.Header
          style={{
            color: '#155724',
            backgroundColor: '#f1fff5',
            borderColor: '#c3e6cb',
          }}
        >
          <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
          <strong className="mr-auto">Item Submission Successful</strong>
          <small>2 secs ago</small>
        </Toast.Header>
        <Link to={{ pathname: `/item/${createdItem.Item.ID}` }}>
          <Toast.Body
            style={{ color: 'rgb(21, 87, 36)', paddingLeft: '1.4rem' }}
          >
            Your item has successful been added
            <Button style={{ marginTop: '1rem' }} variant="outline-primary">
              Click to view your Item
            </Button>
          </Toast.Body>
        </Link>
      </Toast>
    );
  };

  let onChange = (date) => setStartDate(date);
  let onChange2 = (date) => setEndDate(date);

  // returns array of events owned by the current user
  const eventsOwned = (events, id) => {
    let p = [];
    if (user.Role === 'Engineer') {
      return events;
    }
    events.forEach((e) => {
      if (e.Roles) {
        e.Roles.forEach((f) => {
          if (f.AccountID === id) p.push(e);
        });
      }
    });
    return p;
  };

  // get all events available
  const getAllEvents = () => {
    const authHead = `Bearer ${user.Token ? user.Token : ''}`;
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: authHead,
      },
    };
    const Link = `${envs.url}events`;

    axios
      .get(Link, headers)
      .then((res) => {
        setEvents(eventsOwned(res.data, user.ID));
      })
      .catch((err) => console.log(err));
  };

  const saveItem = (e) => {
    setShowToast(false);
    setShowErrorToast(false);
    e.preventDefault();

    // there are images waiting to be uploaded
    let stopAction = false;
    itemImages.currentImages.map((image) => {
      if (image.uploaded === false) stopAction = true;
    });
    if (stopAction === true) {
      setShowErrorToast(true);
      setTimeout(() => setShowErrorToast(false), 2000);
      return false;
    }

    if (!selectedEvent.ID) {
      e.preventDefault();
      alert('SELECT AN EVENT TO CONTINUE!');
      return false;
    }

    const authHead = `Bearer ${user.Token ? user.Token : ''}`;

    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: authHead,
      },
    };

    const Link = `${envs.url}items`;

    // if (!CharityId) {
    //     alert(
    //       "No valid charity associated with your account, you can\t make an event"
    //     );
    //   }

    let array_of_Links = [];
    if (e.target.itemImage) {
      if (e.target.itemImage.length > 1) {
        const d = Array(...e.target.itemImage);
        d.forEach((e) => {
          array_of_Links.push(e.value);
        });
      } else {
        array_of_Links.push(e.target.itemImage.value);
      }
    }

    // turn current images into an array of urls
    const imagesArr = [];
    itemImages.currentImages.forEach((image) => imagesArr.push(image.url));

    const payload = {
      EventID: selectedEvent.ID,
      Name: e.target.itemName.value,
      Description: e.target.description.value,
      Number: e.target.itemNumber.value,
      Images: imagesArr,
      Value: e.target.itemValue.value ? e.target.itemValue.value : '',
      Type: 0,
      Sold: false,
      Shipping: e.target.shipping.value ? e.target.shipping.value : '',
      BidStart: e.target.bidStart.value ? e.target.bidStart.value : 100.0,
      BidIncrease: e.target.bidIncrease.value
        ? e.target.bidIncrease.value
        : 50.0,
      BuyItNow: e.target.buyItNow.value ? e.target.buyItNow.value : 500.0,
      BidCurrent: {},
      // BidEndDate: "2020-11-07T17:00:00Z",
      CreateDate: new Date().toISOString(),
      BidEndDate: selectedEvent.EventEnd,
      BidHistory: [],
    };

    document.getElementById('itemName').value = '';
    document.getElementById('itemNumber').value = '';
    document.getElementById('description').value = '';
    document.getElementById('itemValue').value = '';
    document.getElementById('bidStart').value = '';
    document.getElementById('bidincrease').value = '';
    document.getElementById('buyItNow').value = '';
    document.getElementById('shipping').value = '';

    // console.log("Payload:", payload);
    axios
      .post(Link, payload, headers)
      .then((res) => {
        setCreatedItem(res.data);
        setShowToast(true);
        setSavedItem(true);
        document.getElementById('item-form').reset();
        setTimeout(() => setSavedItem(false), 1200);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAllEvents();
    dispatch(clearItemImages());
  }, []);

  const handleChange = (ev) => {
    const { currentTarget } = ev;
    const image = currentTarget.files[0];
    // this stops the app from breaking when a user presses "cancel" when selecting an image
    if (image) dispatch(addImage(image));
  };

  const uploadPressed = (ev) => {
    itemImages.currentImages.forEach((image, index) => {
      if (!image.uploaded) return dispatch(uploadImage(ev, image, index));
    });
  };

  return (
    <div className="">
      {showToast && createdItem ? showMsg(createdItem) : <></>}
      <RAAToast
        showToast={showErrorToast}
        toggleShow={closeErrorToast}
        title="Create Item Error"
        message="There are images waiting to be uploaded!"
        type="success"
      />
      <div className="createItem cards">
        <Form onSubmit={saveItem} enctype="multipart/form-data">
          <Form.Group as={Row} controlId="formHorizontalEmail">
            <Form.Label column sm={3}>
              Item Name
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="text"
                placeholder="Name"
                name="itemName"
                id="itemName"
                required
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formHorizontalEmail">
            <Form.Label column sm={3}>
              Item Number
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="number"
                min={0}
                placeholder="#"
                name="itemNumber"
                id="itemNumber"
                onChange={(e) => {
                  if (e.target.value < 0) e.target.value = 0;
                }}
                required
              />
            </Col>
          </Form.Group>

          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>Item Description</Form.Label>
            <Form.Control
              as="textarea"
              rows="3"
              id="description"
              name="description"
            />
          </Form.Group>

          <Form.Group>
            <div className="App">
              <center>
                {/* <h1>UPLOAD IMAGE</h1> */}

                {itemImages.success ? (
                  <p style={{ color: 'green' }}>SUCCESSFUL UPLOAD</p>
                ) : itemImages.uploading ? (
                  <p>Image Uploading...</p>
                ) : (
                  <p>Select an image for upload</p>
                )}

                <div className="input-and-images-container">
                  {/* Limit the amount of images that can be uploaded to 3 */}
                  <input
                    onChange={handleChange}
                    type="file"
                    name="file"
                    id={
                      itemImages.currentImages.length >= 3
                        ? 'imageUploadInputDisabled'
                        : 'imageUploadInput'
                    }
                    disabled={itemImages.currentImages.length >= 3}
                    required
                  />
                  <label
                    for={
                      itemImages.currentImages.length >= 3
                        ? 'imageUploadInputDisabled'
                        : 'imageUploadInput'
                    }
                  >
                    <FontAwesomeIcon icon={faFileArrowUp} size="4x" />
                  </label>
                  {itemImages.currentImages.length
                    ? itemImages.currentImages.map((image, index) => (
                        <ImagePreview src={image.previewUrl}>
                          {image.url ? (
                            <SuccessMark
                              onClick={() => dispatch(removeImage(index))}
                            >
                              <FontAwesomeIcon icon={faCheckCircle} size="lg" />
                            </SuccessMark>
                          ) : (
                            <DeleteButton
                              onClick={() => dispatch(removeImage(index))}
                            >
                              X
                            </DeleteButton>
                          )}
                        </ImagePreview>
                      ))
                    : null}
                </div>
                <br />
                <UploadButton onClick={uploadPressed}>UPLOAD</UploadButton>
              </center>
            </div>
          </Form.Group>

          <Form.Group as={Row} controlId="formHorizontalEmail">
            <Form.Label column sm={3}>
              Item Value
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="number"
                name="itemValue"
                id="itemValue"
                onChange={(e) => {
                  if (e.target.value < 0) e.target.value = 0;
                }}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formHorizontalEmail">
            <Form.Label column sm={3}>
              Bid Start
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="number"
                placeholder="100"
                name="bidStart"
                id="bidStart"
                onChange={(e) => {
                  if (e.target.value < 1) e.target.value = 1;
                }}
                required
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formHorizontalEmail">
            <Form.Label column sm={3}>
              Minimum Bid Increase
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="number"
                placeholder="50"
                name="bidIncrease"
                id="bidincrease"
                onChange={(e) => {
                  if (e.target.value < 1) e.target.value = 1;
                }}
                required
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formHorizontalEmail">
            <Form.Label column sm={3}>
              Buy It Now Price
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="number"
                placeholder="500.00"
                name="buyItNow"
                id="buyItNow"
                onChange={(e) => {
                  if (e.target.value < 1) e.target.value = 1;
                }}
                required
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formHorizontalShipping">
            <Form.Label column sm={3}>
              Shipping Price
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="number"
                placeholder="10.00"
                name="shipping"
                id="shipping"
                onChange={(e) => {
                  if (e.target.value < 0) e.target.value = 0;
                }}
              />
            </Col>
          </Form.Group>

          <h6 style={{ color: 'gray', cursor: 'default' }}>
            Dates are preset to {selectedEvent.Name}
          </h6>
          <Form.Group>
            <Form.Label style={{ marginRight: '10px' }}>Start Date:</Form.Label>
            <DateTimePicker
              onChange={onChange}
              value={startDate}
              name="startDate"
              id="startDate"
              disabled
            />
          </Form.Group>
          <Form.Group>
            <Form.Label style={{ marginRight: '15px' }}>End Date:</Form.Label>
            <DateTimePicker
              onChange={onChange2}
              value={endDate}
              name="endDate"
              id="endDate"
              disabled
            />
          </Form.Group>

          <Form.Group as={Row}>
            <Col style={{ textAlign: 'center' }}>
              {savedItem ? (
                <>
                  <Button type="submit" variant="success">
                    Item Saved!
                  </Button>
                </>
              ) : (
                <Button
                  type="submit"
                  variant="primary"
                  disabled={itemImages.currentImages.length < 1}
                  style={
                    itemImages.currentImages.length < 1
                      ? { cursor: 'default' }
                      : { cursor: 'pointer' }
                  }
                >
                  Save Item!
                </Button>
              )}
            </Col>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
};



export default Create;
