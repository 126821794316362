import axios from 'axios';
import envs from '../../env';

export const addImage = (image) => (dispatch) => {
  dispatch({
    type: 'SI_ADD_IMAGE',
    payload: image,
  });
};

export const removeImage = (index) => (dispatch) => {
  dispatch({
    type: 'SI_REMOVE_IMAGE',
    payload: index,
  });
};

export const uploadImage = (ev, image) => (dispatch) => {
  ev.preventDefault();

  dispatch({ type: 'SI_SET_UPLOAD_SUCCESS', payload: false });

  const fileNameAndExtension = image.name.split('.');
  const fileName = fileNameAndExtension[0];
  const fileType = fileNameAndExtension[1];

  dispatch({ type: 'SI_SET_IMAGE_UPLOADING', payload: true });

  axios
    .post(`${envs.url}images/upload`, { fileName, fileType })
    .then((response) => {
      var returnData = response.data;
      var signedRequest = returnData.signedRequest;
      dispatch({
        type: 'SI_ADD_URL_TO_IMAGE',
        payload: returnData.url,
      });

      axios
        .put(signedRequest, image, { headers: { 'Content-Type': fileType } })
        .then(() => {
          dispatch({ type: 'SI_SET_IMAGE_UPLOADING', payload: true });
          dispatch({
            type: 'SI_SET_UPLOAD_SUCCESS',
            payload: true,
          });
        })
        .catch((error) => {
          // TODO: maybe put a dispatch here for failed uploads
          alert('ERROR ' + JSON.stringify(error));
        });
    })
    .catch((error) => {
      // TODO: dispatch for failed uploads here too
      console.log('error:', error);
      alert(JSON.stringify(error));
    });
};
