import axios from 'axios';
import envs from '../../env';

// STRUCTURE OF A FILE OBJ
/**
 *  lastModified: number;
 *  lastModifiedDate: Date;
 *  name: string;  //fileName.png
 *  size: number;
 *  type: string;  //image/png
 *  webkitRelativePath: string;
 */

export const addImage = (image) => (dispatch) => {
  dispatch({
    type: 'ADD_IMAGE',
    payload: image,
  });
};

export const removeImage = (index) => (dispatch) => {
  dispatch({
    type: 'REMOVE_IMAGE',
    payload: index
  })
}

export const clearItemImages = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_ALL',
  });
};

export const uploadImage = (ev, image, index) => (dispatch) => {
  ev.preventDefault();
  dispatch({ type: 'SET_UPLOAD_SUCCESS', payload: false });
  
  const fileNameAndExtension = image.name.split('.');
  const fileName = fileNameAndExtension[0].replace(/\s+/g, '');
  const fileType = fileNameAndExtension[1];
  
  dispatch({ type: 'SET_IMAGE_UPLOADING', payload: true });
  
  axios
    .post(`${envs.url}images/upload`, { fileName, fileType })
    .then((response) => {
      var returnData = response.data;
      var signedRequest = returnData.signedRequest;
      dispatch({ type: 'ADD_URL_TO_IMAGE', payload: {index, url: returnData.url} });
      // console.log("Recieved a signed request ", signedRequest);

      axios
        .put(signedRequest, image, {headers: { 'Content-Type': fileType }})
        .then(() => {
          dispatch({ type: 'SET_IMAGE_UPLOADING', payload: true });
          dispatch({ type: 'SET_UPLOAD_SUCCESS', payload: {index, status: true} });
        })
        .catch((error) => {
          alert("ERROR " + JSON.stringify(error));
        });
    })
    .catch((error) => {
      console.log("error:", error);
      alert(JSON.stringify(error));
    });
};

export const loadImages = (images) => (dispatch) => {
  dispatch({
    type: 'LOAD_IMAGES',
    payload: images
  })
}