import React, { useContext } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { Navbar, Nav, Button } from 'react-bootstrap';
import NavDropdown from 'react-bootstrap/NavDropdown'
import Cookies from 'js-cookie';

import './Navigation.css';
import DashNavigation from './DashNavigation'
import { UserContext } from '../../UserContext';

// redux
import { useDispatch } from 'react-redux';
import { signout } from '../../redux/actions/dashboardActions';

const Navigation = (props) => {
  const dispatch = useDispatch();
  const [user, setUser] = useContext(UserContext);

  // console.log('navigation component global user', user);
  
  const clickLogout = () => {
    dispatch(signout());
    const loggedOutUser = {
      Role: '',
      LoggedIn: false,
      Name: '',
      Email: '',
      Password: '',
      Token: '',
      IsTwoFA: false,
      ID: ''
    }
    setUser(loggedOutUser);
    Cookies.remove('Token');

    console.log("LOGOUT", user.LoggedIn);
    props.history.push('/');
  }

  const roles = {
    Engineer: "Engineer",
    Owner: "Owner",
    Admin: "Admin"
  }

  const checkRoleStatus = (x) => {
    switch (x) {
      case 'Engineer':
      case 'Owner':
      case 'Admin':
        return true;
      default:
        return false;
    }
  }

  const handlePage = (path) => {
    const reg = new RegExp("Dashboard", "gi");
    const y = reg.test(path)
    // console.log(reg.test(path), toString(reg.test(path)), toString(reg.test(path)) ? console.log('royally') : console.log('chad'))

    switch (y) {
      case true:
        return <DashNavigation DashNav={props.DashNav}/>
      default:
        return(
          <div className="Navigation">
            <div className="Navigation-inner">
              <Navbar collapseOnSelect expand="md" variant="dark" sticky="top">
                <Navbar.Brand className=" Brand" style={{
                      padding: "0.5rem 0.8rem",
                      background: "#4a4c58",
                      boxShadow: "0 0 5px rgba(0,0,0,0.5)",
                      borderRadius: "5px"
                }}><NavLink to="/" exact>RAA</NavLink></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="" id="links">
                  <NavLink className="nav-link side" to="/learnmore" >Learn More About RAA Live!</NavLink>
                </Nav>
                  <Nav className="ml-auto" id="links">
                    {/* USER LOGGED IN */}
                    {user.LoggedIn === true && (<>
                      <NavDropdown
                        id="nav-dropdown"
                        title={`Hello, ${user.FirstName}`}
                        menuvariant="dark"
                        className="pr-2"
                      >
                        {/* USER IS ADMIN */}
                        {checkRoleStatus(user.Role) && (
                          <NavDropdown.Item>
                            <NavLink
                              className="dropdown-nav-link"
                              activeClassName="selected"
                              to={user.LoggedIn === true ? '/dashboard' : '/login'}
                            >
                              <li>Dashboard</li>
                            </NavLink>
                          </NavDropdown.Item>
                        )}
                        <NavDropdown.Item>
                          <NavLink
                            className="dropdown-nav-link"
                            activeClassName="selected"
                            to={`/user/${user.ID}`}
                          >
                            <li>My Auctions</li>
                          </NavLink>
                        </NavDropdown.Item>
                      </NavDropdown>
                      <Button
                        variant="danger"
                        id="register-btn"
                        onClick={clickLogout}
                      >
                        Sign out
                      </Button>
                    </>)}

                    {/* USER NOT LOGGED IN */}
                    {!user.LoggedIn && (<>
                      <Button
                        variant="outline-info"
                        onClick={() => {props.history.push('/login')}}
                      >
                        Login
                      </Button>
                      <Button
                        id="signup-btn"
                        variant="info"
                        onClick={() => {props.history.push('/signup')}}
                      >
                        Signup
                      </Button>
                    </>)}
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </div>
          </div>
        )
    }
  }

  
  return(
    <>
      {handlePage(props.location.pathname)}
    </>
  )
};

export default withRouter(Navigation);