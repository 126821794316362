const initialState = {
  file: null,
  message: '',
  previewUrl: '',
  url: '',
  isLoading: false,
  uploading: false,
  success: false,
  uploaded: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'SI_ADD_IMAGE':
      const image = payload;
      return {
        ...state,
        file: image,
        previewUrl: URL.createObjectURL(payload),
        uploaded: false,
      };

    case 'SI_REMOVE_IMAGE':
      return { ...initialState };

    case 'SI_ADD_URL_TO_IMAGE':
      return { ...state, url: payload };

    case 'SI_SET_UPLOAD_SUCCESS':
      return { ...state, uploaded: payload, success: true };

    case 'SI_SET_IMAGE_UPLOADING':
      return { ...state, uploading: payload };

    default:
      return { ...state };
  }
};
