import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from "react";
import {
  Button,
  Form,
  InputGroup,
  FormControl,
  Badge,
  Card,
  ListGroup,
  Container,
  OverlayTrigger,
  Overlay,
  Tooltip,
  Modal,
  Alert,
  Col,
  Row,
} from "react-bootstrap";
import { Link, Redirect, useHistory } from "react-router-dom";
import { UserContext } from "../../UserContext";
import Cookies from "js-cookie";
import moment from "moment";
import axios from "axios";

import "./Item.css";
import envs from "../../env";

const pImg =
  "https://images.unsplash.com/photo-1513151233558-d860c5398176?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80";

let today = new Date();
today = today.toISOString();

// Buy it now confirmation

const AuctionItem = (props, { location, match }) => {
  const [user, setUser] = useContext(UserContext);
  const [itemID, setItemID] = useState("");
  const [currentItem, setCurrentItem] = useState(props.current);
  const [itemUpdate, setItemUpdate] = useState({});
  const [avail, setAvail] = useState(false);
  const [aucSent, setAucSent] = useState(false);
  const [suggCount, setSuggCount] = useState(4);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [show, setShow] = useState(false);
  // for buy it now
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [status, setStatus] = useState();
  const target = useRef(null);
  let winningText = "Congrats! You are the highest bidder!";
  let losingText = "You are not the highest bidder for this item!";

  const history = useHistory();

  // setAlertMessage(error.response.data.Message);

  const getItemsC = useCallback(
    (id) => {
      const authHead = `Bearer ${user.Token ? user.Token : ""}`;

      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: authHead,
        },
      };

      const Link = `${envs.url}items?ItemID=${id}`;

      console.log(Link, headers);
      axios
        .get(Link, headers)
        .then((res) => setCurrentItem(res.data))
        .catch((err) => console.log(err));
    },
    [user.Token]
  );

  const getItems = useCallback(
    (id) => {
      const authHead = `Bearer ${user.Token ? user.Token : ""}`;

      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: authHead,
        },
      };

      const Link = `${envs.url}items?ItemID=${id}`;

      // console.log(Link, headers);
      axios
        .get(Link, headers)
        .then((res) => {
          try {
            setItemUpdate(res.data);
            setAvail(true);
            // console.log(itemUpdate);
          } catch (e) {
            console.log("auction get items:" + e);
          }
        })
        .catch((err) => console.log(err.response.data.Message));
    },
    [user.Token]
  );

  const bid = (
    amount,
    accountID,
    ItemID,
    firstname,
    lastname,
    cell,
    status = false
  ) => {
    const authHead = `Bearer ${user.Token ? user.Token : ""}`;

    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: authHead,
      },
    };

    const Link = `${envs.url}items/bid`;

    //console.log(amount, ItemID, username, accountID);
    let payload = {
      ID: ItemID,
      Bid: {
        AccountID: accountID,
        Amount: amount,
        FirstName: firstname,
        LastName: lastname,
        Cell: cell,
      },
    };

    if (status) {
      payload.Status = status;
    }

    axios
      .patch(Link, payload, headers)
      .then((res) => {
        try {
          props.setShowToast(true);
          setSuccess(true);
          setTimeout(() => setSuccess(false), 500);
        } catch (e) {
          console.log("auction bid send: " + e);
        }
      })
      .catch((err) => {
        console.log("Auction bid error:", err.response.data.Message);
        {
          err.response.data.Message == "No Bearer Token!"
            ? handleShow2()
            : console.log("Non-token auction bid error.", err.data);
        }
        setError(true);
        setTimeout(() => setError(false), 500);
      });
  };

  // has the useeffect only run after inital mount
  const isInitalMount = useRef(true);

  useEffect(() => {
    if (isInitalMount.current) {
      isInitalMount.current = false;
    } else {
      setStatus(itemUpdate ? itemUpdate.Status : currentItem.Status);
    }
  });

  const checkStatus = useCallback(() => {
    if (itemUpdate.Status)
      setStatus(itemUpdate ? itemUpdate.Status : currentItem.Status);
  }, [itemUpdate]);

  useEffect(() => {
    setCurrentItem(props.current);
    getItems(currentItem.ID);
    // console.log(itemUpdate);
    checkStatus();

    const Interval = setInterval(() => {
      if (currentItem.Status === "Live") {
        getItems(currentItem.ID);
      }
    }, 1000);
    return () => clearInterval(Interval);
  }, [getItems, currentItem.ID]);

  const sendBIN = () => {
    props.setShowToast(false);
    setShow(false);
    if (avail) {
      const bidVal = itemUpdate.BuyItNow;
      const HighestVal =
        itemUpdate.BidCurrent && itemUpdate.BidCurrent.Amount
          ? itemUpdate.BidCurrent.Amount
          : itemUpdate.BidStart;

      if (parseFloat(bidVal) >= parseFloat(HighestVal)) {
        bid(
          parseFloat(bidVal),
          user.ID,
          itemUpdate.ID,
          user.FirstName,
          user.LastName,
          user.Cell,
          "Cart"
        );
      } else {
        setError(true);
        setTimeout(() => setError(false), 500);
        alert(`The Current bid is already higher than the Buy It Now price.`);
      }
    } else {
      console.log("sorry, please wait");
    }
    handleClose();
  };

  const sendAutoBid = () => {
    const authHead = `Bearer ${user.Token ? user.Token : ""}`;

    let amount;

    // if (!isNaN(parseInt(document.getElementById("autoBid_input").value))) {
    //   alert('Autobid only takes numbers. Please input a number.');
    //   return;
    // }

    console.log(
      document.getElementById("autoBid_input"),
      document.getElementById("autoBid_input").length,
      document.getElementById("autoBid_input").value
    );

    if (document.getElementById("autoBid_input").value.length > 0) {
      amount = document.getElementById("autoBid_input").value * 1;
    } else {
      alert("Autobid Text Field must have Input.");
      return;
    }

    if (isNaN(amount) || amount === 0) {
      alert("input must be a number greater than 0");
      return;
    }

    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: authHead,
      },
    };

    const Link = `${envs.url}items/bid`;

    let payload = {
      ID: currentItem.ID,
      Bid: {
        AccountID: user.ID,
        Amount: amount,
        Autobid: 1,
        FirstName: user.FirstName,
        LastName: user.LastName,
        Cell: user.Cell,
      },
    };

    axios
      .patch(Link, payload, headers)
      .then((res) => {
        console.log("autobid sent");
        try {
          props.setShowToast(true);
          setSuccess(true);
          setTimeout(() => setSuccess(false), 500);
        } catch (e) {
          console.log("auto bid send: " + e);
        }
      })
      .catch((err) => {
        {
          err.response.data.Message == "No Bearer Token!"
            ? handleShow2()
            : console.log("Non-token auction bid error.", err.data);
        }
        setError(true);
        setTimeout(() => setError(false), 500);
      });
  };

  const submitBid = () => {
    props.setShowToast(false);
    setShow(false);
    if (avail) {
      const bidVal = document.getElementsByName("amount")[0].value;
      const HighestVal =
        itemUpdate.BidCurrent && itemUpdate.BidCurrent.Amount
          ? itemUpdate.BidCurrent.Amount
          : itemUpdate.BidStart;

      if (
        parseInt(bidVal) > parseInt(HighestVal) ||
        (parseInt(bidVal) >= parseInt(HighestVal) &&
          HighestVal == itemUpdate.BidStart)
      ) {
        bid(
          parseInt(bidVal),
          user.ID,
          itemUpdate.ID,
          user.FirstName,
          user.LastName,
          user.Cell
        );
      } else {
        setError(true);
        setTimeout(() => setError(false), 500);
        alert(`The Current bid is at ${HighestVal}, please add a higher bid.`);
      }
    } else {
      console.log("sorry, please wait");
    }
  };

  // console.log(aucSent);

  const fillWSugg = (i) => {
    const quickChoice = document.getElementsByName("sugg" + i)[0].innerText;
    var temp = quickChoice.replace(/\$/, "");
    document.getElementById("yolo").value = temp;
    setShow(true);
  };
  // console.log(itemUpdate);

  const handleClose = () => setShow2(false);
  const handleShow = () => setShow2(true);

  const handleClose2 = () => setShow3(false);
  const handleShow2 = () => setShow3(true);

  return (
<Card style={{ height: "100%", overflow: "auto" }} className="auctionBidCard">
      <Card.Header style={{ fontWeight: "600", letterSpacing: "1px" }}>
        This auction ends on {moment(currentItem.BidEndDate).format("LLL")}
      </Card.Header>
      <Card.Body style={{ padding: 0, overflowY: "auto" }}>
        <ListGroup variant="flush">
          {status !== "Live" ? (
            <ListGroup.Item>
              <>
                <Badge
                  variant="danger"
                  style={{
                    fontSize: "2rem",
                    borderRadius: "3px",
                    padding: ".25rem 0.20rem",
                  }}
                >
                  Bidding has Ended
                </Badge>

                <div>
                  <br />
                  Winning Bid: $
                  {itemUpdate.BidCurrent && itemUpdate.BidCurrent.Amount
                    ? itemUpdate.BidCurrent.Amount
                    : itemUpdate.BidStart}
                </div>

                <h5 style={{ marginTop: "1rem" }}>
                  Bidding for this item has ended since it is now past the end
                  date, thank you for your interest.
                </h5>
              </>
            </ListGroup.Item>
          ) : (
            ""
          )}
          {status === "Live" ? (
            <div>
              <ListGroup.Item>
                {itemUpdate.BidCurrent && itemUpdate.BidCurrent.Amount ? (
                  <>
                    <div
                      style={{
                        fontSize: "1.2rem",
                        fontWeight: "500",
                        borderRadius: "3px",
                      }}
                    >
                      Current Bid: $
                      {itemUpdate.BidCurrent && itemUpdate.BidCurrent.Amount
                        ? itemUpdate.BidCurrent.Amount
                        : itemUpdate.BidStart}
                    </div>
                    <Badge
                      className="mt-2"
                      variant={
                        itemUpdate && itemUpdate.BidCurrent
                          ? itemUpdate.BidCurrent.AccountID === user.ID
                            ? "success"
                            : "info"
                          : currentItem.BidCurrent.AccountID === user.ID
                          ? "success"
                          : "info"
                      }
                      style={{
                        fontSize: "1rem",
                        borderRadius: "3px",
                        padding: "0.25rem",
                        width: "max-content",
                      }}
                    >
                      {itemUpdate.BidCurrent.AccountID === user.ID
                        ? winningText
                        : losingText}
                    </Badge>
                  </>
                ) : (
                  <div
                    style={{
                      fontSize: "1.2rem",
                      fontWeight: "500",
                      borderRadius: "3px",
                    }}
                  >
                    No Bids Yet
                  </div>
                )}
                <hr></hr>
                <div
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: "500",
                    borderRadius: "3px",
                  }}
                >
                  Bidding Starts at: ${currentItem.BidStart}
                  <br></br>
                  Minimum Bid Increase: ${currentItem.BidIncrease}
                  <br></br>
                  {currentItem.Shipping && currentItem.Shipping !== "" ? (
                    <span>
                      Estimated Shipping Cost: ${currentItem.Shipping}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </ListGroup.Item>
            </div>
          ) : null}
        </ListGroup>
      </Card.Body>
      {status !== "Live" ? null : (
        <Card.Footer>
          <Card.Subtitle style={{ fontSize: "1.25rem" }}>
            Bid in Auction
          </Card.Subtitle>
          <hr></hr>
          <div className="item-pay-section" id="item">
            <div className="item-pay-suggestions">
              <h4
                style={{
                  margin: "0",
                  padding: "0",
                  fontSize: "1.2rem",
                  fontWeight: "500",
                  borderRadius: "3px",
                }}
              >
                Quick Bid:
              </h4>
              <div className="pills-content">
                {/* {avail ? showPills() : <Badge variant="primary">Loading...</Badge>} */}
                {Array.from(Array(suggCount), (_, i) => {
                  // <Form.Control onClick={suggestPrice} className="imgLinksQ" type="url" placeholder="https://images.unsplash.com/photo-149268422" name="imgLinks"/>
                  return (
                    <Button
                      key={i}
                      name={"sugg" + i}
                      className="sugg"
                      onClick={() => fillWSugg(i)}
                      variant="primary"
                    >
                      $
                      {(itemUpdate.BidCurrent && itemUpdate.BidCurrent.Amount
                        ? itemUpdate.BidCurrent.Amount * 1
                        : itemUpdate.BidStart * 1) +
                        (itemUpdate.BidCurrent && itemUpdate.BidCurrent.Amount
                          ? itemUpdate.BidIncrease * 1
                          : 0) +
                        i * itemUpdate.BidIncrease * 1}
                    </Button>
                  );
                })}
              </div>
            </div>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="btnGroupAddon">$</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                id="yolo"
                style={{ maxWidth: "30%" }}
                placeholder="5"
                aria-label="bid-amount"
                aria-describedby="basic-addon2"
                name="amount"
              />
              <InputGroup.Append>
                <Button
                  ref={target}
                  variant={error ? "danger" : success ? "success" : "info"}
                  onClick={submitBid}
                >
                  {error
                    ? "Submit Error!"
                    : success
                    ? "Bid Submitted"
                    : "Submit Bid"}
                </Button>
                <Overlay
                  target={target.current}
                  show={show}
                  placement="right"
                  style={{ background: "red" }}
                >
                  {(props) => (
                    <Tooltip id="overlay-example" {...props}>
                      Click to Submit the Bid!
                    </Tooltip>
                  )}
                </Overlay>
              </InputGroup.Append>
            </InputGroup>
            <div className="AutoBid">
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text
                    style={{
                      fontSize: "1.0rem",
                      fontWeight: "500",
                      borderRadius: "3px",
                    }}
                  >
                    AutoBid
                  </InputGroup.Text>
                  <InputGroup.Text>$</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  placeholder="10"
                  aria-label="Amount"
                  className="autobid_input"
                  id="autoBid_input"
                  style={{ maxWidth: "20%", minWidth: "50px" }}
                />
                <InputGroup.Append>
                  <Button
                    onClick={() => sendAutoBid()}
                    className="Autobid_submit"
                    variant="info"
                    style={{
                      padding: "4px",
                      border: "0.1px solid black",
                      boxShadow: "0px 0px 5px rgba(0,0,0,0.5)",
                    }}
                  >
                    Submit
                  </Button>
                </InputGroup.Append>
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 150, hide: 200 }}
                  overlay={(props) => (
                    <Tooltip id="button-tooltip" {...props}>
                      Automatically bids up by the bid increment, until it
                      reaches your max bid limit! Try it out!
                    </Tooltip>
                  )}
                >
                  <i
                    class="fas fa-info-circle"
                    style={{
                      "align-self": "center",
                      "margin-left": "10px",
                      "font-size": "1.3rem",
                    }}
                  ></i>
                </OverlayTrigger>
              </InputGroup>
            </div>
          </div>
          {currentItem && currentItem.BidStart * 1 > currentItem.BuyItNow ? (
            ""
          ) : itemUpdate.BidCurrent &&
            itemUpdate.BidCurrent.Amount > currentItem.BuyItNow ? (
            ""
          ) : (
            <>
              {user.Token ? (<Button
                variant="success"
                onClick={() => handleShow()}
                style={{
                  margin: 0,
                  fontWeight: "500",
                  padding: ".4rem 1rem",
                  fontSize: "1rem",
                  textShadow: "0px 1px 1px black",
                  boxShadow: "inset 0px 0px 2px 0px black",
                }}
              >
                Click Here to Buy It Now for ${currentItem.BuyItNow}!
              </Button>) : 
              (<Button
                variant="success"
                onClick={() => handleShow2()}
                style={{
                  margin: 0,
                  fontWeight: "500",
                  padding: ".4rem 1rem",
                  fontSize: "1rem",
                  textShadow: "0px 1px 1px black",
                  boxShadow: "inset 0px 0px 2px 0px black",
                }}
              >
                Click Here to Buy It Now for ${currentItem.BuyItNow}!
              </Button>)}
              <Modal show={show2} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Buy It Now Confirmation!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Are you sure you want to Buy it Now?
                  <br />
                  <br />
                  If you Buy This Now, you must pay ${
                    currentItem.BuyItNow
                    }{" "}
                  for this item within 15 minutes or it will go back into the
                  auction.
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={sendBIN}>
                    Let's Buy it!
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal show={show3} onHide={handleClose2}>
                <Modal.Header closeButton>
                  <Modal.Title>You are not yet signed in...</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Please log in to begin bidding. <br />
                  <br />
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="primary"
                    onClick={() => history.push("/login")}
                  >
                    Login
                  </Button>
                  <Button variant="secondary" onClick={handleClose2}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          )}
        </Card.Footer>
      )}
    </Card>
  );
};

export default AuctionItem;
