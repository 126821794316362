import React from 'react';
import {Toast as BootstrapToast} from 'react-bootstrap';

// title: string
// message: string
// type: 'error' | 'success'

const SUCCESS_COLOR = {
  background: 'seagreen',
  border: '#267347'
}
const ERROR_COLOR = {
  background: 'tomato',
  border: 'firebrick'
}

export default function RAAToast({ showToast, toggleShow, title, message, type }) {
  return (
    <BootstrapToast
      show={showToast}
      onClose={toggleShow}
      variant="success"
      animation={true}
      style={{
        width: 'max-content',
        position: 'fixed',
        top: '10px',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: '1000000',
        color: '#fff',
        backgroundColor: type === 'success' ? SUCCESS_COLOR.background : ERROR_COLOR.background,
        borderColor: type === 'success' ? SUCCESS_COLOR.border : ERROR_COLOR.border,
      }}
    >
      <BootstrapToast.Header
        style={{
          color: '#fff',
          backgroundColor: type === 'success' ? SUCCESS_COLOR.background : ERROR_COLOR.background,
          borderColor: type === 'success' ? SUCCESS_COLOR.border : ERROR_COLOR.border,
          fontSize: '1.25rem',
        }}
      >
        <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
        <strong className="mr-auto">{title}</strong>
      </BootstrapToast.Header>

      <BootstrapToast.Body
        style={{ color: '#fff', paddingLeft: '1.4rem', fontSize: '1rem' }}
      >
        {message}
      </BootstrapToast.Body>
    </BootstrapToast>
  );
}