import styled, { keyframes } from 'styled-components';

const gradient = keyframes`
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
  }
`;

export const EventBannerContainerFull = styled.div`
  max-width: 1600px;
  height: fit-content;
  max-height: 400px;
  border-radius: 0.25rem;
  margin: 1rem auto;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EventBannerImageFull = styled.img`
  max-width: 100%;
  height: auto;
`;

export const EventBannerContainerSplit = styled.div`
  max-width: 820px;
  min-height: 400px;
  margin: 1rem auto;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
`;

export const ImageContainerSplit = styled.div`
  width: clamp(320px, 50%, 400px);
  max-height: 400px;
  overflow-y: hidden;
`;
export const ImageSplit = styled.img`
  width: 100%;
  height: auto;
`;

export const EventBannerDescriptionSplit = styled.div`
  width: clamp(320px, 50%, 400px);
  min-width: 320px;
  min-height: 400px;
  background: linear-gradient(
    -45deg,
    ${randomColor()},
    ${randomColor()},
    ${randomColor()},
    ${randomColor()}
  );
  background-size: 400% 400%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border-radius: 0.25rem;
  padding: 0.75rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: ${gradient} 15s ease infinite;
`;

export const EventBannerDescriptionHeader = styled.h3`
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
  text-align: center;
`;
export const EventBannerDescriptionP = styled.p`
  font-size: 1.3rem;
  color: #fff;
`;

//=================================================================
//                      BANNER PREVIEWS
//=================================================================
export const EventBannerContainerFullPreview = styled.div`
  max-width: 800px;
  height: 200px;
  max-height: 200px;
  border-radius: 0.25rem;
  margin: 1rem auto;
  background: linear-gradient(
    -45deg,
    ${randomColor()},
    ${randomColor()},
    ${randomColor()},
    ${randomColor()}
  );
  background-size: 400% 400%;
  animation: ${gradient} 15s ease infinite;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const EventBannerContainerSplitPreview = styled.div`
  max-width: 410px;
  min-height: 200px;
  margin: 1rem auto;

  display: flex;
  gap: 10px;
`;

export const EventBannerImageSplitPreview = styled.div`
  width: 50%;
  overflow-y: scroll;
  background-color: #eee;
  background-position: center;
  background-size: cover;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const EventBannerDescriptionSplitPreview = styled.div`
  position: relative;
  width: 50%;
  background: linear-gradient(
    -45deg,
    ${randomColor()},
    ${randomColor()},
    ${randomColor()},
    ${randomColor()}
  );
  background-size: 400% 400%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border-radius: 0.25rem;
  padding: 0.75rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: ${gradient} 15s ease infinite;
`;

// Helper functions
function randomColor() {
  const allowedCharacters = [
    '0',
    '1',
    '2',
    '4',
    '5',
    '6',
    '7',
    '9',
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
  ];
  const darker = ['0', '1', '2', '4', '5', '6', '7', '9'];

  let p = `#${darker[Math.floor(Math.random() * darker.length)]}${
    allowedCharacters[Math.floor(Math.random() * allowedCharacters.length)]
  }${darker[Math.floor(Math.random() * darker.length)]}${
    allowedCharacters[Math.floor(Math.random() * allowedCharacters.length)]
  }${darker[Math.floor(Math.random() * darker.length)]}${
    allowedCharacters[Math.floor(Math.random() * allowedCharacters.length)]
  }
    `;
  return p;
}
