import React, { useState, useContext, useCallback } from 'react';
import { Link, withRouter, Redirect } from 'react-router-dom';
import { Form, Button, InputGroup, FormControl, Alert } from 'react-bootstrap';
import axios from 'axios';

import "./Register.css";
import { UserContext } from "../../UserContext";
import envs from '../../env';

const Signup = (props) => {
  const [user, setUser] = useContext(UserContext);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [twoFA, setTwoFA] = useState(false);

  const handleUser = (fName, lName, mail, pass) => {
    const newUser = {
      loggedIn: true,
      Name: `${fName} ${lName}`,
      Email: mail,
      Password: pass,
    };
    setUser(newUser);
  };

  const goToLogin = (e) => {
    // e.preventDefault();
    props.history.push({
      pathname: '/login',
      state: {fromSignIn: true}
    });
  };

  // const handleUser = (uname, email, pass) => {
  //   const newUser = {
  //     ...user,
  //     Email: email,
  //     IsTwoFA: twoFA,
  //   };
  //   setUser(newUser);
  // };

  // const email = "thaddious@gmail.com";
  // const password = "password";
  // const cell = '';

  const formSubmit = (e) => {
    e.preventDefault();
    let firstName = e.target.firstName.value;
    let lastName = e.target.lastName.value;
    let email = e.target.email.value;
    let password = e.target.password.value;
    let cell = e.target.cell.value;
    axios
      .post(
        `${envs.url}account`,
        {
          FirstName: `${firstName}`,
          LastName: `${lastName}`,
          Email: `${email}`,
          Password: `${password}`,
          Cell: `1${cell}`,
          // "IsTwoFA": twoFA
        }
      )
      .then(res => {
        console.log(res);
        handleUser(firstName, lastName, email, password);
        goToLogin();
      })
      .catch(
        error => {
          console.log('error', error);
          setAlertMessage(error.response.data.Message);
          setShowAlert(true);
        }
      );
  }

  return (
    <div className="Signup-wrapper">
      <div className="Signup-content">
        <h3>Sign up</h3>
        {
          showAlert ? 
            <Alert variant={'danger'}>
              {alertMessage}
            </Alert>
          :null
        }
        <Form onSubmit={formSubmit} className="signup-form">
          <Form.Control
            type="text"
            name="firstName"
            placeholder="First Name"
            required
          />
          <Form.Control
            type="text"
            name="lastName"
            placeholder="Last Name"
            required
          />
          <Form.Control
            type="email"
            placeholder="Email"
            name="email"
            required
          />
          <Form.Control
            type="password"
            placeholder="Password"
            name="password"
            required
          />
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>+1</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              id="formPhone"
              type="tel"
              name="cell"
              placeholder="3131234567"
              required
            />
          </InputGroup>
          <Form.Text className="text-muted mb-2">
            We text you bid alerts.
          </Form.Text>
          {/* <Form.Group controlId="twoFACheckbox">
            <Form.Check type="checkbox"
                        label="Enable 2-Factor Auth"
                        onClick={() => setTwoFA(!twoFA)} />
          </Form.Group> */}
          {twoFA ? (
            <>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>+1</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  id="formPhone"
                  type="tel"
                  name="phone"
                  placeholder="3131234567"
                  required
                />
              </InputGroup>
              <Form.Text className="text-muted mb-2">
                We'll text you a code.
              </Form.Text>
            </>
          ) : null}
          <Button variant="primary" type="submit">
            Create Account
          </Button>
          <div className="suggestion-text">
            <Link to="/login">already have an account?</Link>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default withRouter(Signup);
