import React from 'react';
import dayjs from 'dayjs';
import moment from 'moment';
// import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import Badge from 'react-bootstrap/Badge';

// styles
import '../Gallery/Gallery.css';
import { Card, Image } from './style';

/**
 * PROPS {
 *  event: {
 *    ID: string;
 *    Name: string;
 *    Images: Array<string>;
 *    Description: string;
 *    EventStart: string;
 *    EventEnd: string;
 *    Sponsor: Array<string>;
 *    CharityID: string;
 *  },
 * }
 */

const EventCard = ({ event }) => {
  return (
    <Card>
      <Card.Body>
        <Link to={{ pathname: `/event/${event.ID}` }}>
          <Image src={event.Images[0]} rounded fluid />
        </Link>
        <Card.Title style={{ height: 50 }}>{event.Name}</Card.Title>
        <Card.Text
          style={{
            height: 50,
            // whiteSpace: 'nowrap',
            overflow: 'scroll',
            // textOverflow: 'ellipsis'
          }}
        >
          {event.Description ? event.Description : 'No description.'}
        </Card.Text>
      </Card.Body>
      <ListGroup className="list-group-flush">
        <ListGroupItem>
          <div className="text-center">
            <Link
              className="visit-event-link"
              to={{
                pathname: `/event/${event.ID}`,
              }}
            >
              <Button
                className="visit-event-btn"
                size="md"
                variant="outline-primary"
                block
              >
                Visit the Event
              </Button>
            </Link>
          </div>
        </ListGroupItem>
        <ListGroupItem>
          {/* NO SPONSORS */}
          {!event.Sponsor?.length && (
            <span className="text-muted">No sponsors</span>
          )}
          {/* HAS SPONSORS */}
          {event.Sponsor?.length > 0 && (
            <Accordion>
              <Accordion.Toggle
                as={Card.Subtitle}
                variant="link"
                className="mt-1 text-muted"
                eventKey="0"
                style={{
                  cursor: 'pointer',
                  fontWeight: '400',
                }}
              >
                <span>Sponsors</span>
              </Accordion.Toggle>
              {event.Sponsor ? (
                <Accordion.Collapse eventKey="0">
                  <>
                    {event.Sponsor.map((d, ind) => (
                      <a href={d.Link}>
                        <Badge key={ind} variant="primary">
                          {d.Name}
                        </Badge>
                        <br />
                      </a>
                    ))}
                  </>
                </Accordion.Collapse>
              ) : null}
            </Accordion>
          )}
        </ListGroupItem>
      </ListGroup>
      <Card.Footer style={{ height: 75 }}>
        <medium className="text-muted">
          {!dayjs().isBefore(event.EventStart) ? (
            <span>Event Ends: {moment(event.EventEnd).format('LLL')}</span>
          ) : (
            <span>
              Event Starts: {dayjs(event.EventStart).format('MMMM DD, YYYY')}
            </span>
          )}
        </medium>
      </Card.Footer>
    </Card>
  );
};

export default EventCard;
