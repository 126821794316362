import React, { useState, useEffect, useCallback, useContext } from "react";
import axios from "axios";
import dayjs from "dayjs";
import { Button } from "react-bootstrap";

// context
import { UserContext } from "../../UserContext";

// custom components
import Gallery from "../Gallery/Gallery";
import EmblaCarousel from "../EmblaCarousel";
import CarouselWrapper from "../CarouselWrapper";

// styles
import "../Home/Home.css";
import envs from "../../env";

const Events = (props) => {
  const [futureEvents, setFutureEvents] = useState([]);
  const [activeEvents, setActiveEvents] = useState([]);
  const [expiredEvents, setExpiredEvents] = useState([]);
  const [user, setUser] = useContext(UserContext);

  const [selected, setSelected] = React.useState([]);

  const goToContact = () => {
    props.history.push("/contact");
  };

  const getEvents = useCallback(() => {
    const authHead = `Bearer ${user.Token ? user.Token : ""}`;

    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: authHead,
      },
    };

    axios
      .get(`${envs.url}events?includeOwned=1`, headers)
      .then((res) => {
        // console.log(res);
        let activeArr = [],
          expiredArr = [],
          futureArr = [];

        for (let event of res.data) {
          if (
            dayjs().isBefore(event.EventEnd) &&
            !dayjs().isBefore(event.EventStart)
          ) {
            activeArr.push(event);
          } else if (dayjs().isAfter(event.EventEnd)) {
            expiredArr.push(event);
          } else if (dayjs().isBefore(event.EventStart)) {
            futureArr.push(event);
          }
        }
        setActiveEvents(activeArr);
        setExpiredEvents(expiredArr);
        setFutureEvents(futureArr);

        //setEvents(res.data);
      })
      .catch((err) => console.log(err));
  }, [user]);

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <div className="Events">
      <div className="hero-banner">
        <div className="banner-events-text" style={{ textAlign: "center" }}>
          <h1>Raffles And Auctions</h1>
          <p>Thanks for choosing us to host your events!</p>
          <Button variant="light" onClick={goToContact}>
            Contact Us
          </Button>
        </div>
      </div>
      
      {/* ACTIVE EVENTS */}
      {activeEvents.length && activeEvents.length > 0 ? (
        activeEvents.length <= 3 ? (
          <Gallery
            galleryTitle="LIVE Events"
            variant="events"
            dataList={activeEvents}
          />
        ) : (
          <CarouselWrapper header="LIVE Events">
            <EmblaCarousel array={activeEvents} />
          </CarouselWrapper>
        )
      ) : null}

      {/* UPCOMING EVENTS */}
      {futureEvents.length && futureEvents.length > 0 ? (
        futureEvents.length <= 3 ? (
          <Gallery
            galleryTitle="Upcoming Events"
            variant="events"
            dataList={futureEvents}
          />
        ) : (
          <CarouselWrapper header="Upcoming Events">
            <EmblaCarousel array={futureEvents} />
          </CarouselWrapper>
        )
      ) : null}

      {/* PAST EVENTS */}
      {expiredEvents.length <= 3 ? (
        <Gallery
          galleryTitle="Previous Events"
          variant="events"
          dataList={expiredEvents}
        />
      ) : (
        <CarouselWrapper header="Previous Events">
          <EmblaCarousel array={expiredEvents} />
        </CarouselWrapper>
      )}
    </div>
  );
};

export default Events;
