const initialState = {
  currentCharity: null,
  currentEvent: null,
  usersTableData: {
    users: [],
    userCount: 0,
  },
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SELECT_EVENT':
      return { ...state, currentEvent: payload };
    case 'SELECT_CHARITY':
      return { ...state, currentCharity: payload };
    case 'POPULATE_USERS_TABLE':
      return {
        ...state,
        usersTableData: { users: payload.users, userCount: payload.userCount },
      };
    case 'SIGNOUT':
      return initialState;
    default:
      return state;
  }
};
