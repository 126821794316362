import styled from 'styled-components';
import useWindowSize from '../../hooks/useWindowSize';

export const Embla = styled.div`
  overflow: hidden;
  position: relative;
`;

export const EmblaContainer = styled.div`
  display: flex;
`;

export const EmblaSlide = styled.div`
  position: relative;
  flex: 0 0 fit-content;
`;

export const EmblaButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  outline: 0;
  cursor: pointer;
  background-color: transparent;
  touch-action: manipulation;
  width: 35px;
  height: 35px;
  border: 0;
  fill: #7A8B99;
  :disabled {
    cursor: default;
    opacity: 0.3;
  }
`;

export const EmblaButtonSvg = styled.svg`
  width: 100%;
  height: 100%;
`;
