import React, { useState, useContext, useCallback, useEffect } from 'react';
import { Modal, Button, Table, Toast, Form, Row, Col } from 'react-bootstrap';
import DateTimePicker from 'react-datetime-picker';
import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faFileArrowUp,
} from '@fortawesome/free-solid-svg-icons';

// custom components
import RAAToast from '../../Toast/Toast';

// redux | context
import { UserContext } from '../../../UserContext';
import { useDispatch, useSelector } from 'react-redux';
import {
  addImage,
  removeImage,
  clearItemImages,
  uploadImage,
  loadImages,
} from '../../../redux/actions/itemImagesActions';

// styles
import './EditItemModal.css';
import {
  ImagePreview,
  DeleteButton,
  UploadButton,
  SuccessMark,
} from '../Create/ImageUploadStyles';

import envs from '../../../env';
const EditItemModal = (props) => {
  const dispatch = useDispatch();
  const itemImages = useSelector((state) => state.itemImages);

  const [user, setUser] = useContext(UserContext);
  const [itemID, setItemID] = useState(props.item.ID);
  const [itemName, setItemName] = useState(props.item.Name);
  const [itemNumber, setItemNumber] = useState(props.item.Number);
  const [itemDescription, setItemDescription] = useState(
    props.item.Description
  );
  const [itemValue, setItemValue] = useState(props.item.Value);
  const [itemBidStart, setItemBidStart] = useState(props.item.BidStart);
  const [itemMinBidInc, setItemMinBidInc] = useState(props.item.BidIncrease);
  const [itemBuyItNow, setItemBuyItNow] = useState(props.item.BuyItNow);
  const [itemShipping, setItemShipping] = useState(props.item.Shipping);
  const [endDate, setEndDate] = useState(new Date(props.item.BidEndDate));
  const [startingState, setStartingState] = useState(props.item);

  // IMAGE UPLOADING STATES
  const [successState, setSuccessState] = useState(false);
  const [imgArray, setImgArray] = useState(props.item.Images);

  const [formChanged, setFormChanged] = useState(false);

  const [selectedEvent, setSelectedEvent] = useState(props.currEvent);

  const [showErrorToast, setShowErrorToast] = useState(false);
  const closeErrorToast = () => setShowErrorToast(false);


  useEffect(() => {
    // setFormChanged if currentImages array is different than startingState
    if (itemImages.currentImages.length > 0) {
        if (itemImages.currentImages.length > startingState.Images.length) {

        // Check if currentImages have been uploaded
        let allUploaded = true;

        itemImages.currentImages.forEach((image) => {
          if (!image.uploaded) {
            allUploaded = false;

            setFormChanged(false);
          }

      if (allUploaded) setFormChanged(true);
    });
    }
  }}
  , [itemImages]);
  
  // INPUT CHANGE HANDLERS
  const inputChangeHandlers = {
    name: function (event) {
      setItemName(event.target.value);
      setFormChanged(true);
    },
    number: function (event) {
      if (event.target.value < 0) {
        event.target.value = 0;
        setItemNumber(0);
        setFormChanged(true);
      }
      setItemNumber(event.target.value);
      setFormChanged(true);
    },
    description: function (event) {
      setItemDescription(event.target.value);
      setFormChanged(true);
    },
    image: function (event) {
      setImgArray(event.target.value);
      setFormChanged(true);
    },
    value: function (event) {
      if (event.target.value < 0) {
        event.target.value = 0;
        setItemValue(0);
        setFormChanged(true);
      }
      setItemValue(event.target.value);
      setFormChanged(true);
    },
    bidStart: function (event) {
      if (event.target.value < 1) {
        event.target.value = 1;
        setItemBidStart(1);
      }
      setItemBidStart(event.target.value);
      setFormChanged(true);
    },
    minBidInc: function (event) {
      if (event.target.value < 1) {
        event.target.value = 1;
        setItemMinBidInc(1);
      }
      setItemMinBidInc(event.target.value);
      setFormChanged(true);
    },
    buyItNow: function (event) {
      if (event.target.value < 1) {
        event.target.value = 1;
        setItemBuyItNow(1);
      }
      setItemBuyItNow(event.target.value);
      setFormChanged(true);
    },
    shipping: function (event) {
      if (event.target.value < 0) {
        event.target.value = 0;
        setItemShipping(0);
      }
      setItemShipping(event.target.value);
      setFormChanged(true);
    },
    endDate: function (date) {
      console.log(date);
      setEndDate(date);
      setFormChanged(true);
    },
  };

  const handleChange = (ev) => {
    const { currentTarget } = ev;
    const image = currentTarget.files[0];
    // this stops the app from breaking when a user presses "cancel" when selecting an image
    if (image) dispatch(addImage(image));
  };

  const submitEdit = (e) => {
    e.preventDefault();
    setShowErrorToast(false);
    // there are images waiting to be uploaded
    let stopAction = false;
    itemImages.currentImages.map((image) => {
      if (image.uploaded === false) stopAction = true;
    });

    if (stopAction === true) {
      setShowErrorToast(true);
      setTimeout(() => setShowErrorToast(false), 2000);
    } else {
      const authHead = `Bearer ${user.Token ? user.Token : ''}`;
      const headers = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: authHead,
        },
      };

      const Link = `${envs.url}items`;

      let array_of_Links = [];
      if (e.target.itemImage) {
        if (e.target.itemImage.length > 1) {
          console.log('img if:', e.target.itemImage);
          const d = Array(...e.target.itemImage);
          d.forEach((e) => {
            array_of_Links.push(e.value);
          });
        } else {
          console.log('img else:', e.target.itemImage.value);
          array_of_Links.push(e.target.itemImage.value);
        }
      }

      // turn current images into an array of urls
      const imagesArr = [];
      itemImages.currentImages.forEach((image) => imagesArr.push(image.url));

      const payload = {
        ...props.item,
        ID: itemID,
        EventID: selectedEvent.ID,
        Name: itemName,
        Description: itemDescription,
        Number: itemNumber,
        Images: imagesArr,
        Value: itemValue,
        BidStart: itemBidStart,
        BidIncrease: itemMinBidInc,
        BuyItNow: itemBuyItNow,
        Shipping: itemShipping,
        BidEndDate: endDate.toISOString(),
      };

      axios
        .post(Link, payload, headers)
        .then((res) => {
          console.log('Item Created: ', res);
          // setCreatedItem(res.data)
          // setShowToast(true);
          // setSavedItem(true);
          // document.getElementById('item-form').reset();
          setSuccessState(false);
          // setTimeout(() => setSavedItem(false), 1200);
        })
        .catch((err) => console.log(err));
      props.setItemChanged((i) => {
        console.log(i, !i);
        return !i;
      });
      props.closeModal();
      props.showEdited();
    }
  };

  const uploadPressed = (ev) => {
    itemImages.currentImages.forEach((image, index) => {
      if (!image.uploaded) return dispatch(uploadImage(ev, image, index));
    });
  };

  useEffect(() => {
    setSelectedEvent(props.currEvent);
    dispatch(clearItemImages());
    // load the images from prop into the reducer
    dispatch(loadImages(props.item.Images));
  }, []);

  return (
    <>
      <RAAToast
        showToast={showErrorToast}
        toggleShow={closeErrorToast}
        title="Edit Item Error"
        message="There are images waiting to be uploaded!"
        type="error"
      />
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Item "{props.item.Name}"</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={submitEdit}>
            {/* ITEM NAME */}
            <Form.Group as={Row} controlId="formHorizontalEmail">
              <Form.Label column sm={3}>
                Item Name
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  type="text"
                  placeholder={props.item.Name}
                  value={itemName}
                  onChange={inputChangeHandlers.name}
                  name="itemName"
                  required
                />
              </Col>
            </Form.Group>
            {/* ITEM NUMBER */}
            <Form.Group as={Row} controlId="formHorizontalEmail">
              <Form.Label column sm={3}>
                Item #
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  type="number"
                  placeholder={props.item.Number}
                  value={itemNumber}
                  onChange={inputChangeHandlers.number}
                  name="itemNumber"
                  required
                />
              </Col>
            </Form.Group>
            {/* EVENT SELECT */}
            {/* <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label>Which Event is this item in:</Form.Label>
              <Form.Control name="eventSelect" as="select" required>
                {props.events.map(event => 
                  <option value={event.ID}>{event.Name}</option>
                )}
              </Form.Control>
            </Form.Group> */}
            {/* ITEM DESCRIPTION */}
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label>Item Description</Form.Label>
              <Form.Control
                as="textarea"
                rows="3"
                name="description"
                value={itemDescription}
                onChange={inputChangeHandlers.description}
                required
              />
            </Form.Group>
            {/* IMAGE STUFF */}
            <Form.Group>
              <div className="App">
                <center>
                  {/* <h1>UPLOAD IMAGE</h1> */}

                  {itemImages.success ? ( 
                    <p style={{ color: 'green' }}>SUCCESSFUL UPLOAD</p> 
                  ) : itemImages.uploading ? (
                    <p>Image Uploading...</p>
                  ) : (
                    <p>Select an image for upload</p>
                  )}
                  <div className="input-and-images-container">
                    <input
                      onChange={handleChange}
                      type="file"
                      name="file"
                      id={
                        itemImages.currentImages.length >= 3 
                          ? 'imageUploadInputDisabled'
                          : 'imageUploadInput'
                      }
                      disabled={itemImages.currentImages.length >= 3}
                    />
                    <label
                      for={
                        itemImages.currentImages.length >= 3
                          ? 'imageUploadInputDisabled'
                          : 'imageUploadInput'
                      }
                    >
                      <FontAwesomeIcon icon={faFileArrowUp} size="4x" />
                    </label>
                    {itemImages.currentImages.length
                      ? itemImages.currentImages.map((image, index) => (
                          <ImagePreview src={image.previewUrl || image.url}>
                            {image.url ? (
                              <SuccessMark
                                onClick={() => dispatch(removeImage(index))}
                              >
                                <FontAwesomeIcon
                                  icon={faCheckCircle}
                                  size="lg"
                                />
                              </SuccessMark>
                            ) : (
                              <DeleteButton
                                onClick={() => dispatch(removeImage(index))}
                              >
                                X
                              </DeleteButton>
                            )}
                          </ImagePreview>
                        ))
                      : null}
                  </div>
                  <br />
                  <UploadButton onClick={uploadPressed}>UPLOAD</UploadButton>
                </center>
              </div>
            </Form.Group>
            {/* ITEM VALUE */}
            <Form.Group as={Row} controlId="formHorizontalEmail">
              <Form.Label column sm={3}>
                Item Value
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  type="number"
                  placeholder={props.item.Value}
                  value={itemValue}
                  onChange={inputChangeHandlers.value}
                  name="itemValue"
                />
              </Col>
            </Form.Group>
            {/* BID START */}
            <Form.Group as={Row} controlId="formHorizontalEmail">
              <Form.Label column sm={3}>
                Bid Start
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  type="number"
                  placeholder={props.item.BidStart}
                  value={itemBidStart}
                  onChange={inputChangeHandlers.bidStart}
                  name="bidStart"
                  required
                />
              </Col>
            </Form.Group>
            {/* MAXIMUM BID INCREASE */}
            <Form.Group as={Row} controlId="formHorizontalEmail">
              <Form.Label column sm={3}>
                Min. Bid Inc.
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  type="number"
                  placeholder={props.item.BidIncrease}
                  value={itemMinBidInc}
                  onChange={inputChangeHandlers.minBidInc}
                  name="bidIncrease"
                  required
                />
              </Col>
            </Form.Group>
            {/* BUY IT NOW PRICE */}
            <Form.Group as={Row} controlId="formHorizontalEmail">
              <Form.Label column sm={3}>
                Buy It Now Price
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  type="number"
                  min={1}
                  placeholder={props.item.BuyItNow}
                  value={itemBuyItNow}
                  onChange={inputChangeHandlers.buyItNow}
                  name="buyItNow"
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formHorizontalShipping">
              <Form.Label column sm={3}>
                Shipping Price
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  type="number"
                  placeholder={props.item.Shipping}
                  value={itemShipping}
                  onChange={inputChangeHandlers.shipping}
                  name="shipping"
                  id="shipping"
                />
              </Col>
            </Form.Group>
            {/* END DATE */}
            <Form.Group>
              <Form.Label style={{ marginRight: '15px' }}>End Date:</Form.Label>
              <DateTimePicker
                placeholder={props.item.BidEndDate}
                value={endDate}
                onChange={inputChangeHandlers.endDate}
                name="endDate"
              />
            </Form.Group>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="danger"
                onClick={props.handleClose}
                style={{ marginRight: '10px' }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="primary"
                disabled={(itemImages.currentImages.length < 1) || (formChanged === false)}
                style={
                  itemImages.currentImages.length < 1
                    ? { cursor: 'default' }
                    : { cursor: 'pointer' }
                }
              >
                Save Changes
              </Button>
            </div>
          </Form>
        </Modal.Body>
        {/* <Modal.Footer>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default EditItemModal;
