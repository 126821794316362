import axios from 'axios';
import envs from '../../env';

const raaApi = axios.create({
  baseURL: envs.url,
  headers: { 'Content-Type': 'application/json' },
});

// FILTER OUT THE USER IDS FROM THE ITEMS
const getUserIDsFromItems = (items) => {
  let u = new Set();
  items.forEach((i) => {
    if (i.BidHistory && i.BidHistory.length) {
      i.BidHistory.forEach((bid) => {
        u.add(bid.AccountID);
      });
    }
  });
  return u;
};

// SAVE ALL USER OBJS WHO ARE PRESENT IN THE ID ARRAY
const filterUsersByID = (users, ids) => {
  let p = [];
  ids.forEach((id) => {
    p.push(...users.filter((u) => u.ID === id));
  });
  return p;
};

// select charity
export const selectCharity = (charity) => (dispatch) =>
  dispatch({ type: 'SELECT_CHARITY', payload: charity })

export const selectEvent = (event) => (dispatch) =>
  dispatch({ type: 'SELECT_EVENT', payload: event });

export const populateUsersTable = (eventId, token) => async (dispatch) => {
  try {
    // FIRST I NEED TO GET AN ARRAY OF ALL USERS
    const users = await raaApi.get('/accounts', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // THEN I NEED TO GET AN ARRAY OF ALL ITEMS
    const items = await raaApi.get(`/items?EventID=${eventId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // THEN I NEED TO GET AN ARRAY OF THE IDS IN THE BID HISTORY TO FILTER THE USERS
    const ids = getUserIDsFromItems(items.data);

    // NOW I NEED TO GET THE USERS FROM THE IDS. THESE ARE THE USERS THAT HAVE BID ON THE ITEMS
    // THESE USERS WILL BE SHOWN IN THE TABLE.
    const usersInTheEvent = filterUsersByID(users.data, ids);

    // FINALLY DISPATCH THE THING
    dispatch({
      type: 'POPULATE_USERS_TABLE',
      payload: { users: usersInTheEvent, userCount: usersInTheEvent.length },
    });
  } catch (err) {
    console.log('populate table error:', err.response.data.Message);
  }
};

export const signout = () => (dispatch) => {
  dispatch({ type: 'SIGNOUT' });
};
