import styled from 'styled-components';

export const Container = styled.div`
  background-color: #353744;
`;

export const InnerContainer = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;

export const Header = styled.h1`
  color: #fff;
  text-align: center;
  margin-bottom: 0.75rem;
`;
