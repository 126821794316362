import React, { useState, createContext } from 'react';

export const UserContext = createContext();

export const UserInfoProvider = (props) => {
  const [user, setUser] = useState({
    Role: 'none',
    LoggedIn: false,
    Name: '',
    Email: '',
    Password: '',
    Token: '',
    IsTwoFA: false,
    ID: ''
  });

  return(
    <UserContext.Provider value={[user, setUser]}>
      {props.children}
    </UserContext.Provider>
  )
}
