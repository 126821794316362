import React from 'react';

// styles
import { Container, InnerContainer, Header } from './styles';

const CarouselWrapper = ({ header, children }) => (
  <Container>
    <Header>{header}</Header>
    <InnerContainer>{children}</InnerContainer>
  </Container>
);

export default CarouselWrapper;
