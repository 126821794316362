import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Table, Button, Spinner } from "react-bootstrap";
// import Cookies from 'js-cookie'
import axios from "axios";
import moment from "moment";

import "./UsersPage.css";
import { Next } from "react-bootstrap/PageItem";
import envs from "../../env";

const pImg =
  "https://images.unsplash.com/photo-1513151233558-d860c5398176?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80";

const UsersPage = ({ match }) => {
  const [userID, setUserID] = useState(match.params.id);

  const [events, setEvents] = useState([]);
  const [activeItems, setActiveItems] = useState([]);
  const [wonItems, setWonItems] = useState([]);
  const [currentWinner, setCurrentWinner] = useState([]);

  const [ready, setReady] = useState(false);
  const [ready2, setReady2] = useState(false);
  const [loading, setLoading] = useState(true);

  let ran = 0;
  let items = [];
 
  useEffect(() => {
    getEvents();
    if (ready) {
      events.forEach((e) => {
        getItems(e.ID, userID);
        setReady2(true);
      });
    }
  }, [ready]);

  const getEvents = () => {
    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const Link = `${envs.url}events`;

    axios
      .get(Link, headers)
      .then((res) => {
        setEvents(res.data);
        setReady(true);
      })
      .catch((err) => console.log(err));
  };

  const getItems = (paramID, userID) => {
    const authHead = `Bearer ${userID ? userID : ""}`;

    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: authHead,
      },
    };

    const Link = `${envs.url}items?EventID=${paramID}`;

    axios
      .get(Link, headers)
      .then((res) => {
        items.push(...res.data);
        setReady2(false);
        setActiveItems(...filterActiveItems(items, userID));
        setWonItems(...filterWonItems(items));
        setCurrentWinner(...filterCurrentlyWinning(items));
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const filterWonItems = (item) => {
    var y = [];
    y.push(
      item.filter(
        (i) => i.Status === "Cart" && i.BidCurrent.AccountID === userID
      )
    );
    return y;
  };

  const filterActiveItems = (item, AccountID) => {
    var y = item.filter(
      (i) =>
        i.Status === "Live" &&
        i.BidHistory.length &&
        i.BidCurrent.AccountID !== userID
    );

    let outbidItems = [];
    let index = y.length;
    while (index--) {
      let currentItem = y[index];

      let foundUser = false;
      for (let history_index in currentItem.BidHistory) {
        let currentistory = currentItem.BidHistory[history_index];
        if (currentistory.AccountID === AccountID) {
          foundUser = true;
          break;
        }
      }

      if (foundUser) outbidItems.push(currentItem);
    }

    return [outbidItems];
  };

  const filterCurrentlyWinning = (item) => {
    // Items Bidding On
    var y = [];
    let today = new Date();
    today = moment(today.toISOString);
    y.push(
      item.filter(
        (i) => i.Status === "Live" && i.BidCurrent.AccountID === userID
      )
    );
    return y;
  };

  function getCurrentEventName(id) {
    let currentEventName = "";
    let itemID = id;
    let eventsArray = events;

    eventsArray.forEach((e) => {
      if (itemID === e.ID) {
        currentEventName = e.Name;
      }
    });

    return currentEventName;
  }

  //0 -> items won
  //1 -> items that are active
  const returnTableWinner = (data, idx) => {
    let today = new Date();
    today = moment(today.toISOString);
    return (
      <tr key={idx}>
        <td>{data.Number}</td>
        <td>{data.EventID ? (getCurrentEventName(data.EventID)) : ("")}</td>
        <td>{data.Name}</td>
        <td>{moment(data.BidEndDate).format("LLL")}</td>
        <td>$ {data.BidCurrent ? data.BidCurrent.Amount : data.BidStart}</td>
        <td>$ {data.Shipping}</td>
        <td>
          <Link
            to={{
              pathname: `/item/${data.ID}`,
            }}
          >
            <Button variant="info" size="sm" block>
              In your cart!
            </Button>
          </Link>
        </td>
      </tr>
    );
  };

  //0 -> items won
  //1 -> items that are active
  const returnTableItem = (data, idx) => {
    let today = new Date();
    today = moment(today);
    // !moment(dataList.BidEndDate).isAfter(today)
    return (
      <tr key={data.ID}>
        <td>{data.Number}</td>
        <td>{data.EventID ? (getCurrentEventName(data.EventID)) : ("")}</td>
        <td>{data.Name}</td>
        <td>{moment(data.BidEndDate).format("LLL")}</td>
        <td>$ {data.BidCurrent ? data.BidCurrent.Amount : data.BidStart}</td>
        <td>N / A</td>
        <td>
          {!moment(data.BidEndDate).isSameOrBefore(today) ? (
            <Link
              to={{
                pathname: `/item/${data.ID}`,
                params: {
                  item: data,
                },
              }}
            >
              <Button variant="danger" size="sm" block>
                You have been outbid!
              </Button>
            </Link>
          ) : (
            <Link
              to={{
                pathname: `/item/${data.ID}`,
                params: {
                  item: data,
                },
              }}
            >
              <Button variant="danger" size="sm" block>
                Sold
              </Button>
            </Link>
          )}
        </td>
      </tr>
    );
  };

  const returnCurrentlyWinning = (data, idx) => {
    let today = new Date();
    today = moment(today.toISOString);
    return (
      <tr key={data.ID}>
        <td>{data.Number}</td>
        <td>{data.EventID ? (getCurrentEventName(data.EventID)) : ("")}</td>
        <td>{data.Name}</td>
        <td>{moment(data.BidEndDate).format("LLL")}</td>
        <td>$ {data.BidCurrent ? data.BidCurrent.Amount : data.BidStart}</td>
        <td>{(data.CurrentAutoBid && data.CurrentAutoBid.AccountID === userID) ? '$ ' + data.CurrentAutoBid.MaxAmount : 'N / A'}</td>
        {/* <td>$ {data.Shipping}</td> */}
        <td>
          <Link
            to={{
              pathname: `/item/${data.ID}`,
              params: {
                item: data,
              },
            }}
          >
            <Button variant="success" size="sm" block>
              Winning with the highest bid!
            </Button>
          </Link>
        </td>
      </tr>
    );
  };

  return (
    <>
      {loading ? (
        <div className="spinner-cont">
          <Spinner animation="border" variant="primary"></Spinner>
        </div>
      ) : (
        <div className="myAuctions">
          <div className="title">
            <h2>
              <span>MY AUCTIONS</span>
            </h2>
          </div>
          <div className="content">
            <div className="items_active">
              <h2>ITEMS BIDDING ON</h2>
              <div className="activeTable">
                <Table striped border hover responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Event Name</th>
                      <th>Item Name</th>
                      <th>End Date</th>
                      <th>Current Bid</th>
                      <th>Auto Bid</th>
                      {/* <th>Shipping</th> */}
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentWinner.map(returnCurrentlyWinning)}
                    {activeItems && activeItems.length > 0
                      ? activeItems.map(returnTableItem)
                      : ""}
                  </tbody>
                </Table>
              </div>
            </div>

            <div className="items_won">
              {wonItems.length !== 0 ? (
                <div
                  style={{
                    align: "right",
                    float: "right",
                    margin: "auto",
                    padding: "20px",
                    width: "25%",
                  }}
                >
                  <Link to="/checkout">
                    <Button variant="success" size="sm" block>
                      Checkout
                    </Button>
                  </Link>
                </div>
              ) : (
                ""
              )}
              <h2>ITEMS WON</h2>
              <div className="wonTable">
                <Table striped border hover responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Event Name</th>
                      <th>Item Name</th>
                      <th>End Date</th>
                      <th>Winning Bid</th>
                      <th>Shipping</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {wonItems.length === 0
                      ? ""
                      : wonItems.map(returnTableWinner)}
                  </tbody>
                </Table>

                {wonItems.length !== 0 ? (
                  <div
                    style={{
                      align: "center",
                      float: "center",
                      margin: "auto",
                      width: "80%",
                    }}
                  >
                    <Link to="/checkout">
                      <Button variant="success" size="sm" block>
                        Checkout
                      </Button>
                    </Link>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UsersPage;
