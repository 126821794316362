import React, { useState, useEffect, useContext } from "react";
import ReactDOM from "react-dom";
import {
    Accordion,
    Card,
    Form,
    Button,
    Badge,
    Row,
    Col,
    InputGroup,
    FormControl,
    Image,
    Dropdown,
    ListGroup,
    Table,
} from "react-bootstrap";
import Cleave from "cleave.js/react";
import Cookies from "js-cookie";
import axios from "axios";
import moment from "moment";

import PingLog from "../../api/Logger";

import { UserContext } from "../../UserContext";
import "../Register/Register.css";
import "./Checkout.css";
import envs from "../../env";

function loadScript2(total_price, AccountID) {
    const script2 = document.createElement("script");
    script2.innerText = `
paypal.Buttons({
  createOrder: function(data, actions) {
    return actions.order.create({
      purchase_units: [{
        "custom_id": "${AccountID}",
        "amount": {
          "currency_code": "USD",
          "value": "${total_price}"
        }
      }],
      application_context: {
        shipping_preference: 'NO_SHIPPING'
      }
    });
  },
  onApprove: function(data, actions) {
    return actions.order.capture().then(function(details) {
      alert("Thank you for your order, you will now be redirected to the homepage");
      window.location = '/';
      alert('Transaction completed by ' + details.payer.name.given_name);
    });
  }
}).render('#paypal-button-container');
`;

    document.body.appendChild(script2);
}

const script = document.createElement("script");
script.src = "https://www.paypal.com/sdk/js?client-id=" + envs.sb_client_id;
//script.onload = loadScript2;
script.async = true;
document.body.appendChild(script);

const Checkout = ({ location, match }) => {
    const [user, setUser] = useContext(UserContext);
    const [creditCardNo, setCreditCardNo] = useState("");
    const [creditCardType, setCreditCardType] = useState("");
    const [creditCardExpiryDate, setCreditCardExpiryDate] = useState("");
    const [cvv, setCVV] = useState("");
    const [item, setItem] = useState();
    const [price, setPrice] = useState(0);
    const [pickup, setPickup] = useState(false);
    const [paymethod, setPaymethod] = useState("paypal");
    const [useBuyItNow, setUseBuyItNow] = useState(match.params.isBuyItNow);
    const [totalCost, setTotalCost] = useState(0);
    const [shippingTotal, setShippingTotal] = useState(0);
    const [grandTotal, setGrandTotal] = useState(0);
    const [cartItems, setCartItems] = useState([]);

    const checkoutTotal = (list) => {
        let total = 0;
        let shipping_total = 0;
        list.forEach((e) => {
            total += parseFloat(e.BidCurrent.Amount);

            if (e.Shipping && parseFloat(e.Shipping) > 0) {
                shipping_total += parseFloat(e.Shipping);
            }
        });

        let total_plus_shipping =
            parseFloat(total) + parseFloat(shipping_total);
        setTotalCost(total);
        setShippingTotal(shipping_total);
        setGrandTotal(total_plus_shipping);
        return total_plus_shipping;
    };

    useEffect(() => {
        const paramID = match.params.id;

        console.log(envs.sb_client_id);
        PingLog({
            Message: "Paypal Payment Infomation",
            Level: "Info",
            Service: "RAA",
            ClientID: envs.sb_client_id,
            UserID: user.ID,
            User: user,
        });

        // GET ITEMS AND FILTER TO MAKE CART
        // https://developer.paypal.com/docs/api/orders/v2/#definition-purchase_unit_request
        axios
            .get(`${envs.url}items?Status=Cart`)
            .then((res) => {
                let filtered = res.data.filter(
                    (item) => item.BidCurrent.AccountID === user.ID
                );
                setCartItems(filtered);
                let total_price = checkoutTotal(filtered);
                loadScript2(total_price, user.ID);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const onCreditCardChange = (e) => {
        setCreditCardNo(e.target.rawValue);
    };
    const onCreditCardTypeChanged = (type) => {
        setCreditCardType(type);
        // console.log(type);
    };
    const onCreditCardExpiryChange = (e) => {
        setCreditCardExpiryDate(e.target.rawValue);
    };
    const onCVVChange = (e) => {
        setCVV(e.target.rawValue);
    };

    return (
        <div
            className="checkout-container"
            style={{ maxWidth: "1000px", margin: "0 auto" }}
        >
            <h1 className="text-center mt-4" style={{ fontSize: "4rem" }}>
                Checkout
            </h1>
            <Table striped hover responsive>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Event</th>
                        <th>Item</th>
                        <th>End Date</th>
                        <th>Price</th>
                        <th>Shipping</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {cartItems.map((event, index) => {
                        return (
                            <>
                                <tr>
                                    <td>{index + 1}</td>
                                    <td>{event.EventID}</td>
                                    <td className="align-middle thicc-font">
                                        {event.Name}
                                    </td>
                                    <td className="align-middle">
                                        {moment(event.BidEndDate).format("LLL")}
                                    </td>
                                    <td className="">
                                        ${event.BidCurrent.Amount}
                                    </td>
                                    <td className="">
                                        ${event.Shipping ? event.Shipping : 0}
                                    </td>
                                    <td className="">
                                        $
                                        {parseFloat(event.Shipping) +
                                            parseFloat(event.BidCurrent.Amount)}
                                    </td>
                                </tr>
                            </>
                        );
                    })}
                    <tr>
                        <td>
                            <strong>Total:</strong>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="">
                            <strong>${totalCost}</strong>
                        </td>
                        <td>
                            <strong>${shippingTotal}</strong>
                        </td>
                        <td>
                            <strong>${grandTotal}</strong>
                        </td>
                    </tr>
                </tbody>
            </Table>

            {/* PAYPAL BUTTON */}
            <div
                style={{
                    maxWidth: "400px",
                    height: "200px",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    flexDirection: "column",
                    backgroundColor: "#fff",
                    boxShadow:
                        "0 0.5em 1em -0.125em rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.02)",
                    margin: "20px auto 20px",
                }}
            >
                <h1>Payment</h1>

                <div id="paypal-button-container"></div>
            </div>

            {/* <Row> */}
            {/* <Col xs={12} md={6}>
          <div style={{
            width: "100%",
            maxHeight: "400px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            margin: "100px auto 70px"
          }}>
            <div style={{
              backgroundColor: "#fff",
              maxWidth: "600px",
              padding: "50px",
              borderRadius: "3px",
              boxShadow: "0 30px 60px 0 rgba(0,0,0,0.3)"
            }}>
              <h1>Shipping</h1>
              <Form.Group controlId="twoFACheckbox">
                        <Form.Check type="checkbox"
                                    label="Pickup"
                                    onClick={() => setPickup(!pickup)} />
              </Form.Group>
              {pickup ? 
                <Form>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email (optional)" disabled/>
                  </Form.Group>

                  <Form.Group>
                    <Row>
                      <Col>
                        <Form.Control type="text" placeholder="First name" disabled/>
                      </Col>
                      <Col>
                        <Form.Control type="text" placeholder="Last name" disabled/>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={8}>
                        <Form.Control type="text" placeholder="Address" disabled/>
                      </Col>
                      <Col xs={12} sm={4}>
                        <Form.Control placeholder="Apt, Suite, etc." disabled/>
                      </Col>
                    </Row>
                    <Form.Control type="text" placeholder="City" disabled/>
                    <Row>
                      <Col xs={12} sm={8}>
                        <Form.Control as="select" disabled>
                          <option value="AL">Alabama</option>
                        </Form.Control>
                      </Col>
                      <Col xs={12} sm={4}>
                        <Form.Control type="text" placeholder="Zip" disabled/>
                      </Col>
                    </Row>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>+1</InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        id="formPhone"
                        type="tel"
                        name="cell"
                        placeholder="3131234567"
                        disabled
                      />
                    </InputGroup>
                  </Form.Group>

                </Form>
              : <Form>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email (optional)" />
                  </Form.Group>

                  <Form.Group>
                    <Row>
                      <Col>
                        <Form.Control type="text" placeholder="First name" required/>
                      </Col>
                      <Col>
                        <Form.Control type="text" placeholder="Last name" required/>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={8}>
                        <Form.Control type="text" placeholder="Address" required/>
                      </Col>
                      <Col xs={12} sm={4}>
                        <Form.Control placeholder="Apt, Suite, etc." />
                      </Col>
                    </Row>
                    <Form.Control type="text" placeholder="City" required/>
                    <Row>
                      <Col xs={12} sm={8}>
                        <Form.Control as="select" required>
                          <option value="AL">Alabama</option>
                          <option value="AK">Alaska</option>
                          <option value="AZ">Arizona</option>
                          <option value="AR">Arkansas</option>
                          <option value="CA">California</option>
                          <option value="CO">Colorado</option>
                          <option value="CT">Connecticut</option>
                          <option value="DE">Delaware</option>
                          <option value="DC">District Of Columbia</option>
                          <option value="FL">Florida</option>
                          <option value="GA">Georgia</option>
                          <option value="HI">Hawaii</option>
                          <option value="ID">Idaho</option>
                          <option value="IL">Illinois</option>
                          <option value="IN">Indiana</option>
                          <option value="IA">Iowa</option>
                          <option value="KS">Kansas</option>
                          <option value="KY">Kentucky</option>
                          <option value="LA">Louisiana</option>
                          <option value="ME">Maine</option>
                          <option value="MD">Maryland</option>
                          <option value="MA">Massachusetts</option>
                          <option value="MI">Michigan</option>
                          <option value="MN">Minnesota</option>
                          <option value="MS">Mississippi</option>
                          <option value="MO">Missouri</option>
                          <option value="MT">Montana</option>
                          <option value="NE">Nebraska</option>
                          <option value="NV">Nevada</option>
                          <option value="NH">New Hampshire</option>
                          <option value="NJ">New Jersey</option>
                          <option value="NM">New Mexico</option>
                          <option value="NY">New York</option>
                          <option value="NC">North Carolina</option>
                          <option value="ND">North Dakota</option>
                          <option value="OH">Ohio</option>
                          <option value="OK">Oklahoma</option>
                          <option value="OR">Oregon</option>
                          <option value="PA">Pennsylvania</option>
                          <option value="RI">Rhode Island</option>
                          <option value="SC">South Carolina</option>
                          <option value="SD">South Dakota</option>
                          <option value="TN">Tennessee</option>
                          <option value="TX">Texas</option>
                          <option value="UT">Utah</option>
                          <option value="VT">Vermont</option>
                          <option value="VA">Virginia</option>
                          <option value="WA">Washington</option>
                          <option value="WV">West Virginia</option>
                          <option value="WI">Wisconsin</option>
                          <option value="WY">Wyoming</option>
                        </Form.Control>
                      </Col>
                      <Col xs={12} sm={4}>
                        <Form.Control type="text" placeholder="Zip" required/>
                      </Col>
                    </Row>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>+1</InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        id="formPhone"
                        type="tel"
                        name="cell"
                        placeholder="3131234567"
                        required
                      />
                    </InputGroup>
                  </Form.Group>

                </Form>
              }
            </div>
          </div>
        </Col> */}

            {/* <Col xs={12} md={6}>
          <Accordion defaultActiveKey="0" style={{marginTop: "30px"}}>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => setPaymethod("paypal")}>
                PayPal
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body style={{display:"flex",alignItems:"center", justifyContent:"center"}}> */}
            {/* CONTENT */}
            {/* <Button className="paypal-btn" variant="warning">Pay With PayPal</Button>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="1" onClick={() => setPaymethod("credit card")}>
                Credit Card
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body className="credit-card"> */}
            {/* CONTENT */}
            {/* <Form.Group>
                    <Row>
                      <Col xs={12} sm={6}>
                        <Cleave placeholder="Enter your credit card number"
                                options={{
                                  creditCard: true,
                                  onCreditCardTypeChanged
                                }}
                                onChange={e => onCreditCardChange(e)}
                                className="form-field" />   
                      </Col>
                      <Col xs={12} sm={6}>
                        <Badge className="card-type" style={{marginTop:"13px", marginLeft:"10px"}}
                          variant={creditCardType==='mastercard' ? "primary" : "secondary"}>
                          MasterCard
                        </Badge>
                        <Badge className="card-type"
                          variant={creditCardType==='visa' ? "primary" : "secondary"}>
                          Visa
                        </Badge>
                        <Badge className="card-type"
                          variant={creditCardType==='discover' ? "primary" : "secondary"}>
                          Discover
                        </Badge>
                        <Badge className="card-type"
                          variant={creditCardType==='amex' ? "primary" : "secondary"}>
                          AMEX
                        </Badge>
                      </Col>
                    </Row>
                    <Form.Group>
                      <Row>
                        <Col>
                          <Cleave placeholder="MM/YY"
                                  options={{ date: true, datePattern: ["m", "d"] }}
                                  onChange={onCreditCardExpiryChange}
                                  className="form-field" />
                        </Col>
                        <Col>
                          <Cleave placeholder="CVV"
                                  options={{
                                    blocks: [3],
                                    numericOnly: true
                                  }}
                                  onChange={onCVVChange}
                                  className="form-field" />
                        </Col>
                      </Row>
                      <Form.Control name="CardName" type="text" placeholder="Name on card" required/>
                    </Form.Group>
                  </Form.Group>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="2" onClick={() => setPaymethod("crypto")}>
                Crypto
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <Card.Body className="credit-card"> */}
            {/* CONTENT */}
            {/* <Form.Group>
                    <Form.Control name="CryptoAddress" type="text" placeholder="Address" required/>
                    <Form.Control name="CryptoConfirms" type="text" placeholder="Confirm Address" required/>
                  </Form.Group>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
      </Row> */}

            {/* <div className="text-center mt-4 mb-4">
        <Button size="md" variant="success">
          Confirm Purchase
        </Button>
      </div> */}
        </div>
    );
};

export default Checkout;
