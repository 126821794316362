import React, {useContext, useEffect} from 'react'
import { Navbar, Nav, NavDropdown, Container, Button } from 'react-bootstrap';
import {NavLink, withRouter} from 'react-router-dom'
import Cookies from 'js-cookie';
import { UserContext } from '../../UserContext';

// redux
import { useDispatch } from 'react-redux';
import { signout } from '../../redux/actions/dashboardActions';

import './Navigation.css';

const DashNavigation = (props) => {
  const dispatch = useDispatch();
    const [user, setUser] = useContext(UserContext);
      
      // THIS IS FOR TESTING
      const clickLogout = () => {
        dispatch(signout());
        const loggedOutUser = {
          Role: '',
          LoggedIn: false,
          Name: '',
          Email: '',
          Password: '',
          Token: '',
          IsTwoFA: false,
          ID: ''
        }
        setUser(loggedOutUser);
        Cookies.remove('Token');
    
        console.log("LOGOUT", user.LoggedIn);
        props.history.push('/');
      }


      const navItems = (nav, index) => {
          return (
            <NavLink key={index} className="hide" to={`/Dashboard?q=${nav.name}`}>
                <li>{nav.name}</li>
            </NavLink>
          );
      }
    

    return(
        <div className="Navigation">
        <div className="Navigation-inner">
          <Navbar  collapseOnSelect expand="lg" variant="dark" style={{display: 'sticky', top: '0'}}>
            <Navbar.Brand>RAA</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="ml-auto" id="links">
                <NavLink className="nav-link" to="/"><li>Home</li></NavLink>
                {user.LoggedIn === true
                  ? <NavLink className="nav-link" activeClassName="selected" to={`/user/${user.ID}`}><li>My Auctions</li></NavLink>
                  : null
                }
                {/*
                <NavLink className="hide" to={`/Dashboard?q=Events`}><li>Events</li></NavLink>
                <NavLink className="hide" to={`/Dashboard?q=Create`}><li>Create</li></NavLink> */}
                {props.DashNav.map(navItems)}
                {user.LoggedIn === true
                ? <Button variant="outline-info" id="register-btn" onClick={clickLogout}>Sign out</Button>
                : <Button variant="outline-info" onClick={() => (props.history.push('/login'))}>Login</Button>}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>
      )
}

export default withRouter(DashNavigation)