import React, { useCallback, useEffect, useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { Dropdown, FormControl, DropdownButton } from "react-bootstrap";

import _ from "lodash";
import { useLocation, Redirect } from "react-router-dom";
// my components
import DashNav from "../Dash-comp/DashNav/DashNav";
import DashItem from "../Dash-comp/DashItem/DashItem";
import Events from "../Dash-comp/Events/Events";
import Create from "../Dash-comp/Create/Create";
import CreateEvent from "../Dash-comp/Create/CreateEvent";
import UserReport from "./Reports/UserReport";
import envs from "../../env";

import { UserContext } from "../../UserContext";

// redux
import { useSelector, useDispatch } from "react-redux";
import {
  selectCharity,
  selectEvent,
} from "../../redux/actions/dashboardActions";

import "./Dashboard.css";

import packageJson from "../../../package.json";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    &#x25bc;
  </a>
));

const CustomMenu = React.forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    const [value, setValue] = useState("");

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <FormControl
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.toLowerCase().startsWith(value)
          )}
        </ul>
      </div>
    );
  }
);

const Dashboard = (props) => {
  const dispatch = useDispatch();
  const [user, setUser] = useContext(UserContext);
  const [role, setRole] = useState(Cookies.get("xyz"));
  const [openEvent, setOpenEvent] = useState("Dashboard");
  const [navItems, setNavItems] = useState([
    { name: "Dashboard", icon: "fas fa-home" },
    { name: "User Report", icon: "fa fa-users" },
    { name: "Create Item", icon: "fas fa-pencil-alt" },
    { name: "Create Event", icon: "fas fa-pencil-alt" },
  ]);

  const [users, setUsers] = useState([]);
  const [charities, setCharities] = useState([]);
  const [selectedCharity, setSelectedCharity] = useState({
    Name: "Select a Charity",
    ID: "",
    Roles: [],
  });
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState({
    Name: "Select an Event",
    CharityID: "",
    ID: "",
    Roles: [],
  });
  const [finalCharities, setFinalCharities] = useState([]);

  const history = useHistory();

  const params = new URLSearchParams(useLocation().search);

  useEffect(() => {
    if (user.Role === "none") history.push("/");
  }, []);

  useEffect(() => {
    props.dashNavItems(navItems);
    // takes the desired sub-component from URL and sets it equal to current state
    const q = params.get("q");
    setOpenEvent(q ? q : "Dashboard");
  }, [params, openEvent]);

  const SignOut = () => {
    return <Redirect to="/"></Redirect>;
  };

  const renderSwitch = (x) => {
    switch (x) {
      case "Dashboard":
        return (
          <DashItem
            users={users}
            currChar={selectedCharity}
            currEvent={selectedEvent}
          />
        );
      case "User Report":
        return (
          <UserReport currChar={selectedCharity} currEvent={selectedEvent} />
        );
      case "Create":
        return <Create currChar={selectedCharity} currEvent={selectedEvent} />;
      case "Create Event":
        return (
          <CreateEvent currChar={selectedCharity} currEvent={selectedEvent} />
        );
      default:
        return <Create currChar={selectedCharity} currEvent={selectedEvent} />;
        break;
    }
  };
  // Functions for the dropdown starts here ====================================

  useEffect(() => {
    if (user.ID !== "") getEvents();
  }, [selectedCharity]);

  useEffect(() => {
    if (user.ID !== "") {
      getCharities();
      getUsers();
    }
  }, [user]);

  useEffect(() => {
    setCharities(getCharitiesOwned(finalCharities));
  }, [finalCharities]);

  const selectEventHandler = (event) => {
    setSelectedEvent(event);
    dispatch(selectEvent(event));
  };
  const displayDropdownEvents = useCallback(
    (event) => (
      <Dropdown.Item
        key={event.ID}
        eventKey={event.CharityID}
        onClick={() => selectEventHandler(event)}
      >
        {event.Name}
      </Dropdown.Item>
    ),
    []
  );

  // GET USERS
  const getUsers = () => {
    const authHead = `Bearer ${user.Token ? user.Token : ""}`;

    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: authHead,
      },
    };

    const link = `${envs.url}accounts`;

    axios.get(link, headers).then((res) => {
      setUsers(res.data);
    });
  };

  // Get charities that are stored in account roles array:
  const getAccountCharities = (allCharities, userRoles) => {
    //Roles tied to account:
    const accountRoles = userRoles;
    //All charities in charities table:
    const allCharitiesArray = allCharities;
    //Roles tied to charity:
    const charityRoles = [];
    //All charities a user is has roles for:
    const charityRolesArray = [];

    if (user.Role !== "Engineer") {
      if (accountRoles) {
        accountRoles.forEach((r) => {
          if (r.charityID !== "") {
            charityRoles.push(r);
          }
        });

        charityRoles.forEach((r) => {
          allCharitiesArray.forEach((c) => {
            if (r.CharityID == c.ID) {
              charityRolesArray.push(c);
            }
          });
        });

        setFinalCharities(charityRolesArray);
      }
      if (user.Role === "Engineer") {
        setFinalCharities(allCharitiesArray);
      }

      return charityRolesArray;
    }
  };

  const getCharitiesOwned = (charities, id) => {
    let p = [];
    if (user.Role === "Engineer") {

      return charities;
    }

    p = finalCharities;

    return p;
  };

  // GET CHARITIES OWNED BY USER
  const getCharities = () => {
    const authHead = `Bearer ${user.Token ? user.Token : ""}`;

    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: authHead,
      },
    };

    axios.defaults.validateStatus = () => {
      return true;
    };
    axios
      .get(`${envs.url}charities`, headers)
      .then((res) => {
        if (res.data.length > 0)
          setCharities(getCharitiesOwned(res.data, user.ID));

        // set default
        if (res.data.length > 0) {
          getAccountCharities(res.data, user.Roles);
          setSelectedCharity(getCharitiesOwned(res.data, user.ID)[0]);
          dispatch(selectCharity(getCharitiesOwned(res.data, user.ID)[0]));
        }
      })
      .catch((err) => console.log("STRING", err));
    // setCharities(testC); // this requires static data to test
  };

  const getEventsByCharityID = (events, cid) => {
    let p = [];
    events.forEach((e) => {
      if (e.CharityID === cid) p.push(e);
    });
    p.sort((a, b) => (b.CreateDate > a.CreateDate ? 1 : -1));
    return p;
  };

  // GET THE EVENTS FOR THE DROPDOWN
  const getEvents = () => {
    const authHead = `Bearer ${user.Token ? user.Token : ""}`;

    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: authHead,
      },
    };

    axios
      .get(`${envs.url}events?includeOwned=1`, headers)
      .then((res) => {
        setEvents([]);
        // this can only work if there's a selected charity ID, so it has to wait until selected
        if (selectedCharity.ID !== "") {
          setEvents(getEventsByCharityID(res.data, selectedCharity.ID)); // this should work
          let filteredEvents = getEventsByCharityID(
            res.data,
            selectedCharity.ID
          );
          if (
            res.data.length > 0 &&
            filteredEvents[0] &&
            filteredEvents[0].Name !== undefined
          ) {
            setSelectedEvent(
              getEventsByCharityID(res.data, selectedCharity.ID)[0]
            );
            dispatch(
              selectEvent(getEventsByCharityID(res.data, selectedCharity.ID)[0])
            );
          }
        }
      })
      .catch((err) => console.log(err));
  };

  // Functions for the dropdown end here ====================================

  return (
    <div id="shazam-cont">
      <DashNav navBarItems={navItems} />
      <div id="right-cont">
        <h1 className="dash-header">
          {openEvent}{" "}
          <span style={{ fontSize: "16pt" }}>v{packageJson.version}</span>
        </h1>
        <div className="charity-event-dropdowns">
          {selectedCharity && selectedCharity.ID !== "" ? (
            <DropdownButton id="charity-dropdown" title={selectedCharity.Name}>
              {charities.map((c) => {
                return (
                  <Dropdown.Item
                    key={c.ID}
                    onClick={() => {
                      setSelectedCharity(c);
                      dispatch(selectCharity(c));
                    }}
                  >
                    {c.Name}
                  </Dropdown.Item>
                );
              })}
            </DropdownButton>
          ) : (
            setSelectedCharity({ Name: "Select a Charity" })
          )}

          <Dropdown id="events-dropdown">
            <Dropdown.Toggle as={CustomToggle}>
              {selectedEvent.Name}
            </Dropdown.Toggle>
            <Dropdown.Menu as={CustomMenu}>
              {events.map(displayDropdownEvents)}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        {renderSwitch(openEvent)} {/* Handles on click of a nav item */}
      </div>
    </div>
  );
};

export default Dashboard;
