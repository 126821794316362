import React, { useState, useEffect, useContext } from 'react';
import { Button, Card, ListGroup } from 'react-bootstrap';
import {Line, Bar} from 'react-chartjs-2';
import {
    NavLink
  } from "react-router-dom";
import {UserContext} from '../../../UserContext';
import Cookies from 'js-cookie';

import './DashNav.css';

const DashNav = (props) => {
    const [user, setUser] = useContext(UserContext);

    const nItem = (nav, index) => {
        return (
            <NavLink key={index} to={`/Dashboard?q=${nav.name}`}>
                <li>
                    <i className={nav.icon}></i>
                    <a href="#">{nav.name}</a>
                </li>
            </NavLink>
        );
    }
    
    // console.log(navBarItems[0])


    return(
        <div id = "nav">
            <div id="logo">{user.FirstName} {user.LastName}</div>
            <div className="search-cont">
                <input type="text" className="nav-search" placeholder="search"></input>
            </div>
            <div className ="nav-options">
                <ul>
                    {props.navBarItems.map(nItem)}
                </ul>
            </div>
        </div>
    );
} 


export default DashNav;