const initialState = {
  currentImages: [],
  imageMessage: '',
  isLoading: false,
  uploading: false,
  success: false,
  uploaded: false
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'ADD_IMAGE':
      const image = payload;
      image.previewUrl = URL.createObjectURL(payload);
      image.uploaded = false;
      return { ...state, currentImages: [...state.currentImages, image] };
    case 'REMOVE_IMAGE':
      const index = payload;
      state.currentImages.splice(index, 1);
      return { ...state };

    case 'IMAGE_ERROR':
      return { ...state, imageMessage: payload };

    case 'CLEAR_IMAGE_ERROR':
      return { ...state, imageMessage: '' };

    case 'CLEAR_ALL':
      return initialState;

    case 'SET_UPLOAD_SUCCESS':
      const uploadedImageIndex = payload.index;
      if (state.currentImages[uploadedImageIndex])
        state.currentImages[uploadedImageIndex].uploaded = payload.status;
      return { ...state, success: payload.status };

    case 'SET_IMAGE_UPLOADING':
      return { ...state, uploading: payload };

    case 'ADD_URL_TO_IMAGE':
      const imageIndex = payload.index;
      const {url} = payload;
      state.currentImages[imageIndex].url = url;
      return { ...state };

    case 'LOAD_IMAGES':
      const images = payload;
      const updatedImageArr = images.map(image => ({ url: image, uploaded: true }));
      return { ...state, currentImages: updatedImageArr };

    default:
      return state;
  }
};
