import React from "react";
import { Button } from "react-bootstrap";

import "./Footer.css";

import { Row, Col } from "react-bootstrap";

const packageJson = require("../../../package.json");

const Footer = () => {
  const date = new Date();
  
  return (
    <div className="footer" style={{ color: "white" }}>
      <Row className="footer-row">
        <Col className="version-number" style={{ float: "left" }}>
          <div className="copyright-section-2">
            © {new Date().getFullYear()} RAA-Live{" "}
            <span>v{packageJson.version}</span>
          </div>
        </Col>
        <Col className="footer-col-2">
          <div className="copyright-section" >
            Made with{" "}
            <i className="fa fa-heart heart" style={{ color: "#eb3528" }} /> by{" "}
            {""}
            <a target="_blank" href="https://www.pingsoftwareusa.com">
              Ping Software USA
            </a>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Footer;
