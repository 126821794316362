import React from 'react';

const Confirmation = () => {
  return(
    <div style={{'align':'center','text-align':'center','margin':'auto','width':'80%'}}>
      <h1>Order Confirmed</h1>
      <p>Thank you for your order!</p>
    </div>
  )
}

export default Confirmation;