import React, { useState, useContext, useCallback, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { Form, Button, Alert } from "react-bootstrap";
import axios from "axios";
import Cookies from "js-cookie";

import "./Register.css";
// import Dashboard from '../Dashboard/Dashboard';
import { UserContext } from "../../UserContext";
import envs from "../../env";

// NOTICE ME, APMPLIFY

export const LoginTwoFA = (props) => {
  const [user, setUser] = useContext(UserContext);

  const goTo = () => {
    props.history.push("/");
  };

  const formSubmit = () => {
    goTo();
  };

  return (
    <div className="Login-wrapper">
      <div className="Login-content">
        <h3>Login</h3>
        <Form onSubmit={formSubmit}>
          <Form.Control
            type="email"
            placeholder="Email"
            name="email"
            required
          />
          <Form.Control
            className="mb-2"
            type="text"
            placeholder="2FA"
            name="twoFA"
            required
          />
          <Button variant="primary" type="submit">
            Login
          </Button>
          <div onClick={formSubmit}>clicketh me</div>
          <div className="suggestion-text">
            <Link to="/signup">need to create an account?</Link>
          </div>
        </Form>
      </div>
    </div>
  );
};

const Login = (props) => {
  const [user, setUser] = useContext(UserContext);
  const [preUser, setPreUser] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [signedIn, setSignedIn] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [linkBackTo, setlinkBackTo] = useState("");
  const [userToken, setUserToken] = useState("");
  let [newUser, setNewUser] = useState({});

  // console.log("login component global user", user);

  const getAccountInfo = (tok) => {
    const authHead = `Bearer ${tok ? tok : ""}`;

    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: authHead,
      },
    };

    const Link = `${envs.url}accounts?currentUserOnly=1`;

    axios
      .get(Link, headers)
      .then((res) => {
        console.log(res, res.data, res.data[0], res.data[0].ID);
        let eng = checkRole(res.data[0]);
        console.log("AVATAR ENG", eng);
        console.log("RES DATA", res.data);
        Cookies.set("ID", res.data[0].ID);
        Cookies.set("FirstName", res.data[0].FirstName);
        Cookies.set("LastName", res.data[0].LastName);
        Cookies.set("Cell", res.data[0].Cell);
        Cookies.set("role", res.data[0].Role);
        // %p1ng% = true, "" = false
        Cookies.set("xyz", eng); // xyz is engineer
        newUser = {
          ...newUser,
          Name: res.data[0].Name,
          ID: res.data[0].ID,
          Role: `${res.data[0].Role}`,
        };
        setUser(newUser);
        console.log("LOG IN USER STAT", user);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    // console.log(props.history.location.params ? `${props.history.location.params.linkBackTo}` : 'none')
    setlinkBackTo(
      props.history.location.params
        ? `${props.history.location.params.linkBackTo}`
        : ""
    );
    setSignedIn(
      props.history.location.state
        ? props.history.location.state.fromSignIn
        : false
    );
    if (signedIn) {
      showSignedUpAlert();
      window.history.replaceState(null, null, "/login");
    }
  }, [props.history.location.state, signedIn, props.history.location.params]);

  const showSignedUpAlert = () => {
    document.querySelector(".SignUpAlert").classList.remove("SignUpOff");
    document.querySelector(".SignUpAlert").classList.add("SignUpOn");

    setTimeout(() => {
      document.querySelector(".SignUpAlert").classList.remove("SignUpOn");
      document.querySelector(".SignUpAlert").classList.add("SignUpOff");
    }, 5000);
  };

  // Check if engineer
  let checkRole = (user) => {
    let roleStats = {
      Engineer: 100,
      Owner: 10,
      Admin: 1,
      User: 0,
    };
    let role = "User";
    user.Roles.forEach((e) => {
      let tempRole = e.RoleStatus;
      if (roleStats[role] < roleStats[tempRole]) {
        role = tempRole;
      }
    });
    return role;
  };

  const handleLoginNormal = (email, pass, token) => {
    // getAccountInfo(token);
    newUser = {
      ...newUser,
      LoggedIn: true,
      Email: email,
      Password: pass,
      Token: token,
    };
    setUser(newUser);

    Cookies.set("email", email);
    Cookies.set("password", pass);
    Cookies.set("token", token);
    Cookies.set("loggedIn", "true");
    Cookies.set("log", "true");

    if (linkBackTo !== "") {
      props.history.push(`${linkBackTo}`);
    } else {
      props.history.push("/");
    }
  };

  const handleLoginTwoFA = (email, pass) => {
    const newUser = {
      ...user,
      Email: email,
      Password: pass,
    };
    setUser(newUser);
    props.history.push("/login2fa");
  };

  const handleIsTwoFA = (val) => {
    const newUser = {
      ...user,
      IsTwoFA: val,
    };
    setUser(newUser);
  };

  async function formSubmit(e) {
    e.preventDefault();
    let email = e.target.email.value;
    let password = e.target.password.value;

    await axios
      .post(
        `${envs.url}account/login`,
        {
          Email: `${email}`,
          Password: `${password}`,
        },
        { "Content-Type": "application/json" }
      )
      .then((response) => {
        let token = response.data.Token;
        Cookies.set("Token", token, { secure: true });
        console.log("Token cooked!");
        setUser({
          ...response.data.Account,
          Role: checkRole(response.data.Account),
          LoggedIn: true,
          Token: token,
        });
        console.log("User set!");
        props.history.push("/");
      })
      .catch((e) => {
        setAlertMessage(e.response.data.Message);
        setShowAlert(true);
      });
  }

  return (
    <div className="Login-wrapper">
      {signedIn ? (
        <div className="SignUpAlert SignUpOff">You have been Signed Up!</div>
      ) : (
        <></>
      )}
      <div className="Login-content">
        <h3>Login</h3>
        {showAlert ? <Alert variant={"danger"}>{alertMessage}</Alert> : null}
        <Form onSubmit={formSubmit}>
          <Form.Control
            type="email"
            placeholder="Email"
            name="email"
            required
          />
          <Form.Control
            type="password"
            placeholder="Password"
            name="password"
            required
          />
          <a
            href="/forgotpw"
            style={{ fontWeight: "bold", marginBottom: "0.25rem" }}
          >
            Forgot password?
          </a>
          <br />
          {user.IsTwoFA ? (
            <Form.Control
              className="mb-2"
              type="text"
              placeholder="2FA"
              name="twoFA"
              required
            />
          ) : null}
          <Button className="mt-2" variant="primary" type="submit">
            Login
          </Button>
          <div className="suggestion-text">
            <Link to="/signup">need to create an account?</Link>
          </div>
          <hr></hr>
        </Form>
      </div>
    </div>
  );
};

export default withRouter(Login);
