import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { UserContext } from '../../UserContext';
import axios from 'axios';
import envs from '../../env';

// custom components
import Gallery from '../Gallery/Gallery';
import EventBanner from '../EventBanner';

// styles
import './Home.css';

const Charity = ({ match, location }) => {
  const [event, setEvent] = useState({});
  const [user, setUser] = useContext(UserContext);
  const [items, setItems] = useState([]);
  const [filterNoBid, setFilterNoBid] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);

  const paramID = match.params.id; // to get the event

  const handleToggle = () => {
    setFilterNoBid(!filterNoBid);
  };

  const getItems = useCallback(() => {
    const authHead = `Bearer ${user.Token ? user.Token : ''}`;

    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: authHead,
      },
    };

    const Link = `${envs.url}items?EventID=${paramID}`;

    axios
      .get(Link, headers)
      .then((res) => {
        setItems(res.data);

        let filtered = res.data.filter((item) => item.BidHistory.length < 1);
        setFilteredItems(...filteredItems, filtered);
        filteredItems.concat(filtered);
        // console.log("filtered", filteredItems);
      })
      .catch((err) => console.log(err));
  }, [user.Token, paramID]);

  // Charity name unneeded now
  const getEventName = useCallback(() => {
    const headers = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const Link = `${envs.url}events`;

    axios
      .get(Link, headers)
      .then((res) => {
        const c = res.data.filter((event) => event.ID === paramID);
        setEvent(c[0]);
      })
      .catch((err) => console.log(err));
  }, [paramID]);

  useEffect(() => {
    getEventName();
    getItems();
  }, [getItems]);

  const aucOrRaf = (item, type) => {
    // TODO: readme
    // if 0 returns auctions
    // if 1 returns raffles
    return item.filter((e) => {
      return e.Type === type;
    });
  };

  return (
    <div className="Home">
      {event.Properties && event.Properties.Width === 'full' ? (
        <EventBanner full event={event} />
      ) : (
        <EventBanner event={event} />
      )}

      {/* No filter */}
      {filterNoBid && (
        <Gallery
          galleryTitle="Auction Items"
          itemType="auction"
          dataList={aucOrRaf(filteredItems, 0)}
          handleToggle={handleToggle}
        />
      )}
      {/* Filtered items */}
      {!filterNoBid && (
        <Gallery
          galleryTitle="Auction Items"
          itemType="auction"
          dataList={aucOrRaf(items, 0)}
          handleToggle={handleToggle}
        />
      )}
    </div>
  );
};

export default Charity;
