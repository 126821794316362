import React, { useState, useEffect, useContext } from "react";
import { Line, Bar } from "react-chartjs-2";
import {Spinner} from 'react-bootstrap'
import { UserContext } from '../../../UserContext';

import Graph from "../Graph/Graph";
import Table from "../Table/Table";

import "./DashItem.css";

// used for total revenue
const sum = (buyers) => {
  let sums = 0;
  buyers.forEach((b) => {
    sums = b.price + sums;
  });
  return sums;
};

const DashItem = (props) => {
  const [user, setUser] = useContext(UserContext);

  return (
    <div>
      {/* <Graph data={props.data} /> */}
      <div className="under-graph">
        {user.ID !== ""
          ? <Table users={props.users} currChar={props.currChar} currEvent={props.currEvent} buyer={props.data} />
          :
            <div
              style={{width:"100%",
                      height:"100%",
                      display:"flex",
                      justifyContent:"center",
                      alignContent:"center"}}>
              <Spinner animation="grow" variant="danger" style={{margin:"5px"}} />
              <Spinner animation="grow" variant="info" style={{margin:"5px"}} />
              <Spinner animation="grow" variant="primary" style={{margin:"5px"}} />
            </div>
        }
      </div>
    </div>
  );
};

export default DashItem;
