import React, { useState, useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
// import dayjs from 'dayjs';
import dayjs from "./../../dayjs_";
import {
  Card,
  Button,
  Badge,
  ListGroup,
  ListGroupItem,
  Accordion,
} from "react-bootstrap";
import moment from "moment";
import { UserContext } from "../../UserContext";

// styles
import "./Gallery.css";
import { StyledCarousel, CarouselItem, CarouselItemImg } from './GalleryStyles';

const pImg =
  "https://images.unsplash.com/photo-1513151233558-d860c5398176?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80";

const EventCard = (dataList, index) => {
  const [eventImgs, setEventImgs] = useState([]);

  const ItemAsPills = (items, index) => {
    return (
      <Badge
        variant="primary"
        key={index}
        style={{
          padding: ".2rem",
          marginRight: ".5rem",
          fontSize: ".75rem",
          padding: "0.2rem",
          marginRight: "0.5rem",
          fontSize: "0.75rem",
          padding: "0.4rem",
          overflow: "auto hidden",
          boxShadow:
            "rgba(10, 10, 10, 0.1) 0px 0.5em 1em -0.125em, rgba(10, 10, 10, 0.02) 0px 0px 0px 1px",
          color: "black",
          backgroundColor: "rgb(239 239 239)",
          border: "0.4px solid #d8d7d7",
          fontWeight: "400",
        }}
      >
        {items.Name}
        <Badge variant="info" style={{ marginLeft: "3px", fontSize: ".65rem" }}>
          {items.Type
            ? items.RaffleTickets
              ? items.RaffleTickets.length
              : 0
            : items.BidCurrent && items.BidCurrent.length > 0
            ? "$" + items.BidCurrent.Amount
            : "$" + items.BidStart}
        </Badge>
      </Badge>
    );
  };

  // const getItems = (x) => {
  //   const authHead = `Bearer ${
  //     user.Token ? user.Token : ""
  //   }`;

  //   const headers = {
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: authHead,
  //     },
  //   };

  //   const Link = `${envs.url}items?EventID=${x}`;

  //   axios
  //     .get(Link, headers)
  //     .then((res) => {
  //       // console.log('ITEMS:', res.data);
  //       setItems(res.data);
  //       setAvail(true);
  //     })
  //     .catch((err) => console.log(err));
  // };

  const renderCarouselImgs = (imgs, idx) => {
    if (imgs === "") {
      imgs = pImg;
    }

    return (
      // <Carousel.Item key={idx}>
      <img
        className="d-block w-100"
        src={imgs}
        alt="First slide"
        style={{
          borderRadius: "2px",
          marginBottom: "1rem",
          boxShadow: "0 0 10px rgba(0,0,0,1)",
          minHeight: "245px",
          maxHeight: "245px",
        }}
      />
      // </Carousel.Item>
    );
  };

  const [highlight, setHighlight] = useState("");
  const [sponsors, setSponsors] = useState([]);
  const [ended, setEnded] = useState(0);

  useEffect(() => {
    if (dataList?.Sponsor) {
      setHighlight(dataList.Sponsor.shift());
      if (dataList.Sponsor.length > 0) setSponsors(dataList.Sponsor);
    }

    if (!dayjs().isBefore(dataList.EventEnd)) setEnded(1);

    if (dataList.Images.length === 0 || dataList.Images[0] === "") {
      // checks if array is empty
      setEventImgs([pImg]);
    } else {
      setEventImgs(dataList.Images);
    }
    // getItems(dataList.ID);
  }, []);

  // let a_list;
  // if (dataList?.Sponsor && dataList.Sponsor.length !== 0) {
  //   a_list = dataList.Sponsor.shift();
  //   console.log('DL: ', dataList?.Sponsor)
  // }

  return (
    <>
      {!ended ? (
        <Card
          key={index}
          style={{
            width: "20rem",
            border: "0",
            borderRadius: "3px",
            borderColor: "red",
          }}
        >
          <Card.Body>
            <Link
              className="visit-event-link"
              to={{
                pathname: `/event/${dataList.ID}`,
              }}
            >
              {eventImgs.length <= 0 || eventImgs === "" ? (
                <img
                  className="d-block w-100"
                  src={pImg}
                  alt="First slide"
                  style={{
                    borderRadius: "2px",
                    marginBottom: "1rem",
                    boxShadow: "0 0 10px rgba(0,0,0,1)",
                    minHeight: "245px",
                    maxHeight: "245px",
                  }}
                />
              ) : eventImgs.length <= 1 ? (
                <img
                  className="d-block w-100"
                  src={eventImgs[0]}
                  alt="First slide"
                  style={{
                    borderRadius: "2px",
                    marginBottom: "1rem",
                    boxShadow: "0 0 10px rgba(0,0,0,1)",
                    minHeight: "245px",
                    maxHeight: "245px",
                  }}
                />
              ) : (
                <img
                  className="d-block w-100"
                  src={eventImgs[1]}
                  alt="First slide"
                  style={{
                    borderRadius: "2px",
                    marginBottom: "1rem",
                    boxShadow: "0 0 10px rgba(0,0,0,1)",
                    minHeight: "245px",
                    maxHeight: "245px",
                  }}
                />
              )}
            </Link>
            <Card.Title
              className="text-center"
              style={{
                fontWeight: "500",
                fontSize: "1.5rem",
                marginBottom: "0px",
                textAlign: "left !important",
              }}
            >
              {dataList.Name}
            </Card.Title>
            <Card.Text
              style={{
                fontWeight: "300",
                fontSize: "1.25rem",
                padding: ".5rem .5rem .5rem 0",
                margin: "0",
                textAlign: "center",
              }}
            >
              {dataList.Description ? dataList.Description : "No description."}
            </Card.Text>
          </Card.Body>
          <ListGroup className="list-group-flush">
            <ListGroupItem>
              <div className="text-center">
                <Link
                  className="visit-event-link"
                  to={{
                    pathname: `/event/${dataList.ID}`,
                  }}
                >
                  <Button
                    className="visit-event-btn"
                    size="md"
                    variant="outline-primary"
                    block
                  >
                    Visit the Event
                  </Button>
                </Link>
              </div>
            </ListGroupItem>
            <ListGroupItem>
              {highlight ? (
                <Accordion>
                  <Accordion.Toggle
                    as={Card.Subtitle}
                    variant="link"
                    className="mb-2 text-muted"
                    eventKey="0"
                  >
                    <ListGroup
                      style={{ padding: 0 }}
                      className="list-group-flush"
                    >
                      <ListGroupItem
                        style={{
                          paddingLeft: 0,
                          paddingRight: 0,
                          display: "grid",
                          gridTemplateColumns: "3fr 1fr",
                          color: "#007bff",
                        }}
                      >
                        <span
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                        >
                          Sponsors:
                        </span>
                        <span
                          style={{
                            display: "flex",
                            justifySelf: "flex-end",
                            alignSelf: "flex-end",
                            cursor: "pointer",
                          }}
                          // href=''
                        >
                          <a
                            style={{ marginLeft: "5px", fontSize: ".9rem" }}
                            href={highlight.Link}
                          >
                            <Badge variant="primary">{highlight.Name}</Badge>
                          </a>
                        </span>
                      </ListGroupItem>
                    </ListGroup>
                  </Accordion.Toggle>
                  {sponsors ? (
                    <Accordion.Collapse
                      eventKey="0"
                      style={{
                        paddingTop: ".1rem",
                        paddingBottom: ".1rem",
                        overflowX: "auto",
                        overflowY: "hidden",
                        padding: "0.4rem",
                        overflow: "auto hidden",
                        boxShadow:
                          "rgba(10, 10, 10, 0.1) 0px 0.5em 1em -0.125em, rgba(10, 10, 10, 0.02) 0px 0px 0px 1px",
                        color: "black",
                        border: "0.4px solid #d8d7d7",
                      }}
                    >
                      <>
                        {sponsors.map((d, ind) => (
                          <a href={d.Link}>
                            <Badge key={ind} variant="primary">
                              {d.Name}
                            </Badge>
                            <br />
                          </a>
                        ))}
                      </>
                    </Accordion.Collapse>
                  ) : null}
                </Accordion>
              ) : null}
            </ListGroupItem>
          </ListGroup>
          <Card.Footer>
            <medium className="text-muted">
              {!dayjs().isBefore(dataList.EventStart) ? (
                <span>
                  Event Ends: {moment(dataList.EventEnd).format("LLL")}
                </span>
              ) : (
                <span>
                  Event Starts:{" "}
                  {dayjs(dataList.EventStart).format("MMMM DD, YYYY")}
                </span>
              )}
            </medium>
          </Card.Footer>
        </Card>
      ) : (
        <Card
          key={index}
          style={{
            background: "#343a40",
            width: "20rem",
            border: "0",
            borderRadius: "3px",
            boxShadow: "rgb(0 0 0 / 30%) 0px 3px 3px 3px",
            color: "#f8f9fa",
          }}
        >
          <Card.Body>
            <Link
              className="visit-event-link"
              to={{
                pathname: `/event/${dataList.ID}`,
              }}
            >
              {eventImgs.length <= 0 || eventImgs == "" ? (
                <img
                  className="d-block w-100"
                  src={pImg}
                  alt="First slide"
                  style={{
                    borderRadius: "2px",
                    marginBottom: "1rem",
                    boxShadow: "0 0 10px rgba(0,0,0,1)",
                    minHeight: "245px",
                    maxHeight: "245px",
                  }}
                />
              ) : eventImgs.length <= 1 ? (
                <img
                  className="d-block w-100"
                  src={eventImgs[0]}
                  alt="First slide"
                  style={{
                    borderRadius: "2px",
                    marginBottom: "1rem",
                    boxShadow: "0 0 10px rgba(0,0,0,1)",
                    minHeight: "245px",
                    maxHeight: "245px",
                  }}
                />
              ) : (
                <img
                  className="d-block w-100"
                  src={eventImgs[1]}
                  alt="First slide"
                  style={{
                    borderRadius: "2px",
                    marginBottom: "1rem",
                    boxShadow: "0 0 10px rgba(0,0,0,1)",
                    minHeight: "245px",
                    maxHeight: "245px",
                  }}
                />
              )}
            </Link>
            <Card.Title
              className="text-center"
              style={{
                fontWeight: "500",
                fontSize: "1.5rem",
                marginBottom: "0px",
                textAlign: "left !important",
              }}
            >
              {dataList.Name}
            </Card.Title>
            <Card.Text
              style={{
                fontWeight: "300",
                fontSize: "1.25rem",
                padding: ".5rem .5rem .5rem 0",
                margin: "0",
                textAlign: "center",
              }}
            >
              {dataList.Description ? dataList.Description : "No description."}
            </Card.Text>
          </Card.Body>
          <ListGroup
            className="list-group-flush"
            style={{ backgroundColor: "rgb(230, 228, 228)" }}
          >
            <ListGroupItem style={{ background: "#343a40" }}>
              <div className="text-center">
                <Link
                  className="visit-event-link"
                  to={{
                    pathname: `/event/${dataList.ID}`,
                  }}
                >
                  <Button
                    className="visit-event-btn"
                    size="md"
                    variant="outline-danger"
                    block
                  >
                    See Previous Items
                  </Button>
                </Link>
              </div>
            </ListGroupItem>
            <ListGroupItem style={{ background: "#343a40" }}>
              {highlight ? (
                <Accordion>
                  <Accordion.Toggle
                    as={Card.Subtitle}
                    variant="link"
                    className="mb-2 text-muted"
                    eventKey="0"
                  >
                    <ListGroup
                      style={{ padding: 0, background: "#343a40" }}
                      className="list-group-flush"
                    >
                      <ListGroupItem
                        style={{
                          paddingLeft: 0,
                          paddingRight: 0,
                          background: "#343a40",
                          display: "grid",
                          gridTemplateColumns: "3fr 1fr",
                          color: "white",
                        }}
                      >
                        <span
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                        >
                          Sponsors:
                        </span>

                        <span
                          style={{
                            display: "flex",
                            justifySelf: "flex-end",
                            alignSelf: "flex-end",
                            cursor: "pointer",
                          }}
                          // href=''
                        >
                          <a
                            style={{ marginLeft: "5px", fontSize: ".9rem" }}
                            href={highlight.Link}
                          >
                            <Badge variant="primary">{highlight.Name}</Badge>
                          </a>
                        </span>
                      </ListGroupItem>
                    </ListGroup>
                  </Accordion.Toggle>
                  {sponsors ? (
                    <Accordion.Collapse
                      eventKey="0"
                      style={{
                        paddingTop: ".1rem",
                        paddingBottom: ".1rem",
                        overflowX: "auto",
                        overflowY: "hidden",
                        padding: "0.4rem",
                        overflow: "auto hidden",
                        boxShadow:
                          "rgba(10, 10, 10, 0.1) 0px 0.5em 1em -0.125em, rgba(10, 10, 10, 0.02) 0px 0px 0px 1px",
                        color: "black",
                        background: "#343a40",
                        border: "0.4px solid #d8d7d7",
                      }}
                    >
                      <>
                        {sponsors.map((d, ind) => (
                          <a href={d.Link}>
                            <Badge key={ind} variant="primary">
                              {d.Name}
                            </Badge>
                            <br />
                          </a>
                        ))}
                      </>
                    </Accordion.Collapse>
                  ) : null}
                </Accordion>
              ) : null}
            </ListGroupItem>
          </ListGroup>
          <Card.Footer>
            <medium className="text-muted">
              <span style={{ color: "red" }}>
                Event Ended: {dayjs(dataList.EventEnd).format("MMMM DD, YYYY")}
              </span>
            </medium>
          </Card.Footer>
        </Card>
      )}
    </>
  );
};

const Gallery = (props) => {
  let location = useLocation();

  const renderCarouselImgs = (imgs, idx) => {
    if (imgs === "") imgs = pImg;

    return (
      <StyledCarousel variant="dark">
          {imgs.map((img) => (
            <CarouselItem key={idx}>
              <CarouselItemImg
              className="d-block w-100"
              src={img}
              alt="First slide"
            />
            </CarouselItem>
          ))}
      </StyledCarousel>
    );
  };

  const ItemCard = (dataList, index) => {
    const [user, setUser] = useContext(UserContext);
    return (
      <Card key={index} style={{ border: "0", borderRadius: ".5rem" }}>
        <Card.Img
          variant="top"
          src={dataList.Images[0] ? dataList.Images[0] : pImg}
          alt="charity logo"
          style={{ minHeight: "270px", maxHeight: "270px" }}
        />
        <Card.Body>
          <Card.Title
            style={{
              fontWeight: "300",
              fontSize: "1.75rem",
              marginBottom: "0",
            }}
          >
            {dataList.Name}
          </Card.Title>
          <Card.Text
            style={{
              fontWeight: "300",
              fontSize: "1.5rem",
              padding: "0",
              margin: "2rem 1rem",
            }}
          >
            {dataList.Description}
          </Card.Text>
          <div className="text-center">
            <Link
              to={{
                pathname: `/item/${dataList.ID}`,
                params: {
                  item: dataList,
                },
              }}
            >
              <Button variant="primary" size="lg">
                Bid
              </Button>
            </Link>
          </div>
        </Card.Body>
      </Card>
    );
  };

  const Raffle = (dataList, index) => {
    const [user, setUser] = useContext(UserContext);
    return (
      <Card key={index} style={{ border: "0", borderRadius: ".5rem" }}>
        <Card.Img
          variant="top"
          src={dataList.Images[0] ? dataList.Images[0] : pImg}
          style={{ height: "250px" }}
          alt="charity logo"
        />
        <Card.Body>
          <Card.Title
            style={{
              fontWeight: "500",
              fontSize: "1.5rem",
              marginBottom: "0",
            }}
          >
            {dataList.Name}
          </Card.Title>
          <Card.Text
            style={{
              fontWeight: "300",
              fontSize: "1.25rem",
              padding: ".5rem 0",
              margin: "0",
            }}
          >
            {dataList.Description ? dataList.Description : "No Description"}
          </Card.Text>
        </Card.Body>
        <ListGroup className="list-group-flush">
          <ListGroupItem>
            <div className="text-center">
              {user.LoggedIn === true ? (
                <Link
                  to={{
                    pathname: `/item/${dataList.ID}`,
                    params: {
                      item: dataList,
                    },
                  }}
                >
                  <Button variant="primary" size="md" block>
                    Bid
                  </Button>
                </Link>
              ) : (
                <Link
                  to={{
                    pathname: `/login`,
                    params: {
                      linkBackTo: location.pathname,
                    },
                  }}
                >
                  <Button
                    variant="warning"
                    size="md"
                    block
                    style={{ letterSpacing: ".5px" }}
                  >
                    Sign In to Continue
                  </Button>
                </Link>
              )}
            </div>
          </ListGroupItem>
        </ListGroup>
        <Card.Footer className="text-muted mt-2">
          {moment(dataList.BidEndDate).format("LLL")}
        </Card.Footer>
      </Card>
    );
  };
  const Auction = (dataList, index) => {
    const [user, setUser] = useContext(UserContext);
    const [show, setShow] = useState(true);
    let today = new Date();
    today = moment(today.toISOString());
    if (dataList.Status !== "Live") {
      return (
        <div>
          <Card
            bg="dark"
            text="light"
            key={index}
            style={{
              border: "0",
              borderRadius: ".5rem",
              boxShadow: "0px 3px 3px 3px #0000004d",
            }}
          >
            <Card.Header
              style={{
                color: "#dc3545",
                textShadow: "-1px 0px 1px black",
                textAlign: "center",
              }}
            >
              {dataList.BidCurrent.Amount === parseInt(dataList.BuyItNow)
                ? "Item Sold thru Buy it Now!"
                : "This items auction time has ended!"}
            </Card.Header>
            <div
              className="image-outer"
              style={{
                width: "100%",
                height: "250px",
                display: "flex",
                backgroundColor: "#fff",
              }}
            >
              <Card.Img
                className="image-will-scale"
                variant="top"
                src={dataList.Images[0] ? dataList.Images[0] : pImg}
                alt="charity logo"
                style={{
                  maxWidth: "100%",
                  maxHeight: "250px",
                  alignSelf: "center",
                  padding: "15px",
                }}
              />
            </div>
            <Card.Body style={{ borderTop: "1px solid black" }}>
              <Card.Title
                style={{
                  fontWeight: "500",
                  fontSize: "1.5rem",
                  marginBottom: "0",
                }}
              >
                {dataList.Name}
              </Card.Title>
              <div
                className="price"
                style={{ position: "absolute", top: "60px", left: "4px" }}
              >
                <Badge
                  pill
                  variant="info"
                  style={{
                    boxShadow: "0 0 6px 2px #09541ab8",
                    textShadow: "0px 1px 2px black",
                    fontSize: "15px",
                    fontWeight: "500",
                    borderRadius: "3px",
                    padding: "0.3rem",
                  }}
                >
                  Bid Ended At: $
                  <span name="pill" variant="outline-info">
                    {dataList.BidCurrent.Amount
                      ? dataList.BidCurrent.Amount
                      : dataList.BidStart}
                  </span>
                </Badge>
              </div>
              <div
                className="number"
                style={{ position: "absolute", top: "60px", right: "4px" }}
              >
                <Badge
                  pill
                  variant="danger"
                  style={{
                    boxShadow: "0 0 6px 2px #40080d",
                    textShadow: "0px 1px 2px black",
                    fontSize: "15px",
                    fontWeight: "500",
                    borderRadius: "3px",
                    padding: "0.3rem",
                  }}
                >
                  Item # {dataList.Number}
                </Badge>
              </div>
              <Card.Text
                style={{
                  fontWeight: "300",
                  fontSize: "1.25rem",
                  padding: ".5rem 0",
                  margin: "0",
                  overflowY: "auto",
                  maxHeight: "100px",
                }}
              >
                {dataList.Description ? dataList.Description : "No Description"}
              </Card.Text>
            </Card.Body>
            {/* <div className='item-ended-msg'>
                      <p>Item Bidding Time is Up</p> 
                    </div> */}
            <ListGroup className="list-group-flush">
              <ListGroupItem style={{ backgroundColor: "#343a40" }}>
                <div className="text-center">
                  <Link
                    to={{
                      pathname: `/item/${dataList.ID}`,
                      params: {
                        item: dataList,
                      },
                    }}
                  >
                    <Button variant="danger" size="md" block>
                      Item Sold
                    </Button>
                  </Link>
                </div>
              </ListGroupItem>
            </ListGroup>
            <Card.Footer className="text-muted mt-2">
              {moment(dataList.BidEndDate).format("LLL")}
            </Card.Footer>
          </Card>
        </div>
      );
    }

    return (
      <Card
        key={index}
        style={{
          border: "0",
          borderRadius: ".5rem",
          boxShadow: "0px 3px 3px 3px #0000004d",
        }}
      >
        <div
          className="image-outer"
          style={{
            width: "100%",
            height: "250px",
            display: "flex",
            backgroundColor: "#fff",
          }}
        >
          {renderCarouselImgs(dataList.Images)}
        </div>

        {/* <Card.Img
          variant="top"
          src={dataList.Images[0] ? dataList.Images[0] : pImg}
          alt="charity logo"
          style={{ height: "250px" }}
        /> */}
        <Card.Body style={{ borderTop: "1px solid black" }}>
          <Card.Title
            style={{
              fontWeight: "500",
              fontSize: "1.5rem",
              marginBottom: "0",
            }}
          >
            {dataList.Name}
          </Card.Title>
          <div
            className="price"
            style={{ position: "absolute", top: "4px", left: "4px" }}
          >
            <Badge
              pill
              variant="success"
              style={{
                boxShadow: "0 0 6px 2px #09541ab8",
                textShadow: "0px 1px 2px black",
                fontSize: "15px",
                fontWeight: "500",
                borderRadius: "3px",
                padding: "0.3rem",
              }}
            >
              <span name="pill" variant="outline-info">
                {dataList.BidCurrent.Amount
                  ? "Current Bid: $" + dataList.BidCurrent.Amount
                  : "Starting Bid: $" + dataList.BidStart}
              </span>
            </Badge>
          </div>

          <div
            className="number"
            style={{ position: "absolute", top: "4px", right: "4px" }}
          >
            <Badge
              pill
              variant="danger"
              style={{
                boxShadow: "0 0 6px 2px #40080d",
                textShadow: "0px 1px 2px black",
                fontSize: "15px",
                fontWeight: "500",
                borderRadius: "3px",
                padding: "0.3rem",
              }}
            >
              Item # {dataList.Number}
            </Badge>
          </div>
          <Card.Text
            style={{
              fontWeight: "300",
              fontSize: "1.25rem",
              padding: ".5rem 0",
              paddingRight: ".1rem",
              margin: "0",
              overflowY: "auto",
              maxHeight: "100px",
            }}
          >
            {dataList.Description ? dataList.Description : "No Description"}
          </Card.Text>
        </Card.Body>
        <ListGroup className="list-group-flush">
          <ListGroupItem>
            <div className="text-center">
              <Link
                to={{
                  pathname: `/item/${dataList.ID}`,
                  params: {
                    item: dataList,
                  },
                }}
              >
                <Button variant="primary" size="md" block>
                  View Details
                </Button>
              </Link>
            </div>
          </ListGroupItem>
        </ListGroup>
        <Card.Footer className="text-muted mt-2">
          {moment(dataList.BidEndDate).format("LLL")}
        </Card.Footer>
      </Card>
    );
  };

  if (props.itemType) {
    switch (props.itemType) {
      case "auction":
        return (
          <div
            className="Gallery-outer"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h1 className="text-center">{props.galleryTitle}</h1>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Button
                onClick={() => {
                  props.handleToggle();
                }}
                className="mb-5"
                style={{
                  margin: "0",
                  width: "max-content",
                  "font-size": "0.75rem",
                }}
              >
                Click to switch between all items and items with no bids only
                {/* {false ? 'View All Items' : 'Toggle only Items with no bids'} */}
              </Button>
            </div>
            <div className="Gallery-inner">{props.dataList.map(Auction)}</div>
          </div>
        );
      case "raffle":
        return (
          <div className="Gallery-outer">
            <h1 className="text-center">{props.galleryTitle}</h1>
            <div className="Gallery-inner">{props.dataList.map(Raffle)}</div>
          </div>
        );
      default:
        return (
          <div className="Gallery-outer">
            <h1 className="text-center">{props.galleryTitle}</h1>
            <div className="Gallery-inner">nothing to show :(</div>
          </div>
        );
    }
  }

  switch (props.variant) {
    case "events":
      return (
        <div className="Gallery-outer">
          <h1 className="text-center">{props.galleryTitle}</h1>
          <div className="Gallery-inner">{props.dataList.map(EventCard)}</div>
        </div>
      );
    case "items":
      return (
        <div className="Gallery-outer">
          <h1 className="text-center">{props.galleryTitle}</h1>
          <div className="Gallery-inner">{props.dataList.map(ItemCard)}</div>
        </div>
      );
    default:
      return (
        <div className="Gallery-outer">
          <h1 className="text-center">{props.galleryTitle}</h1>
          <div className="Gallery-inner">nothing to show :(</div>
        </div>
      );
  }
};

export default Gallery;
