import React, { useState, useEffect, useCallback, useContext } from "react";
import {
  Button,
  Modal,
  Spinner,
  Toast,
  Card,
  ListGroup,
  Badge,
} from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import { UserContext } from "../../UserContext";
import Cookies from "js-cookie";
import axios from "axios";

import RaffleItem from "./RaffleItem";
import AuctionItem from "./AuctionItem";
import "./item2.css";
import envs from "../../env";

const pImg =
  "https://images.unsplash.com/photo-1513151233558-d860c5398176?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80";

const Item = ({ location, match }) => {
  const [user, setUser] = useContext(UserContext);
  const [currentItem, setCurrentItem] = useState({});
  const [showToast, setShowToast] = useState(false);
  const [whichAction, setWhichAction] = useState(0);
  const [currStatus, setCurrStatus] = useState("Live");
  const [readytoSend, setReadytoSend] = useState(false);

  let history = useHistory();

  // setAlertMessage(error.response.data.Message);

  // console.log(match, match.params.id)

  const toggleShow = () => setShowToast(!showToast);

  const showMsg = () => {
    // setTimeout(() => setShowToast(false), 1300)
    return (
      <Toast
        show={showToast}
        onClose={toggleShow}
        animation={true}
        style={{
          width: "250px",
          position: "absolute",
          top: "75px",
          right: "10px",
          color: "#155724",
          borderColor: "#c3e6cb",
          backgroundColor: "#d4edda",
          fontWeight: "700",
          zIndex: "99",
        }}
      >
        <Toast.Header style={{ color: "#155724" }}>
          <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
          <strong className="mr-auto">Auction Success</strong>
          {/* <small>11 mins ago</small> */}
        </Toast.Header>
        <Toast.Body>Your bid was successful</Toast.Body>
      </Toast>
    );
  };

  useEffect(() => {}, []);

  const getItemsC = useCallback(
    (id) => {
      const authHead = `Bearer ${user.Token ? user.Token : ""}`;

      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: authHead,
        },
      };

      const Link = `${envs.url}items?ItemID=${id}`;

      // console.log(Link, headers);
      axios
        .get(Link, headers)
        .then((res) => {
          // console.log(res.data);
          setCurrentItem(res.data);
          setCurrStatus(res.data.Status);
          setReadytoSend(true);
        })
        .catch((err) => console.log(err));
    },
    [user.Token]
  );

  useEffect(() => {
    setTimeout(() => {
      getItemsC(match.params.id);
    }, 1000);
  }, []);

  const handleCards = (c) => {
    switch (c) {
      case 0:
        return (
          <div className="item-content" style={{ maxWidth: "700px" }}>
            <AuctionItem
              current={currentItem}
              setShowToast={setShowToast}
              setWhichAction={setWhichAction}
            />
          </div>
        );
      case 1:
        return (
          <div className="item-content" style={{ maxWidth: "700px" }}>
            <RaffleItem
              current={currentItem}
              setShowToast={setShowToast}
              setWhichAction={setWhichAction}
            />
          </div>
        );
      default:
        return (
          <div>
            <Spinner
              animation="border"
              variant="primary"
              style={{ height: "5px", width: "50px" }}
            />
          </div>
        );
    }
  };

  return (
    <>
      {showToast ? showMsg() : null}
      <div className="item-cont">
        <div className="item-header">
          {/* <Link to={{pathname:`/event/${currentItem.EventID}}`}}> */}
          <Button
            className="backBtn"
            style={{ height: "100%", borderRadius: 0, margin: 0 }}
            onClick={() => {
              let path = `/event/${currentItem.EventID}`;
              history.push(path);
            }}
          >
            <i class="fa fa-arrow-left" aria-hidden="true">
              {" "}
              <div className="return-btn-text">
              Return To Auction Items
              </div>
            </i>
          </Button>
          {/* </Link> */}
          <h4
            style={{
              fontSize: "1.5rem",
              color: "white",
              alignSelf: "self-end",
            }}
          >
            Auction
          </h4>
        </div>
        <div className="item-pic-cont item-boxs">
          <img
            class="item-img"
            stlye={{ width: "auto", height: "100%" }}
            src={
              currentItem.Images && currentItem.Images[0]
                ? currentItem.Images[0]
                : pImg
            }
            alt={currentItem.Name}
          />
        </div>
        <div className="item-title-cont item-boxs">
          <h4>#{currentItem.Number}</h4>
          <h1 style={{ fontSize: "3em", paddingLeft: "20px" }}>
            {currentItem.Name}
          </h1>
        </div>
        <div className="item-desc-cont item-boxs">
          <Card style={{ height: "100%" }}>
            <Card.Header style={{ fontWeight: "600", letterSpacing: "1px" }}>
              Description
            </Card.Header>
            <Card.Body style={{ overflowY: "auto" }}>
              <Card.Text style={{ overflowY: "auto", overflowX: "hidden" }}>
                <h3 style={{ fontWeight: "300" }}>{currentItem.Description}</h3>
              </Card.Text>
              {/* <Button variant="primary">Go somewhere</Button> */}
            </Card.Body>
            {currentItem.Value && currentItem.Value !== "" ? (
              <ListGroup variant="flush" className="mb-4">
                <ListGroup.Item>
                  <Badge
                    variant="info"
                    style={{
                      fontSize: "1rem",
                      borderRadius: "3px",
                      padding: "0.25rem",
                    }}
                  >
                    Item Value: {currentItem.Value}
                  </Badge>
                </ListGroup.Item>
              </ListGroup>
            ) : (
              ""
            )}
          </Card>
        </div>
        <div className="item-buy-cont item-boxs">
          {readytoSend ? (
            <AuctionItem
              currStats={currStatus}
              current={currentItem}
              setShowToast={setShowToast}
            />
          ) : (
            <div className="SpinContainer">
              <Spinner
                animation="border"
                variant="primary"
                style={{ height: "100px", width: "100px" }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
  // /*old
  // <div className="Item-container">
  //        {showToast ? showMsg(whichAction) : null}
  //        {handleCards(currentItem.Type)}
  // </div>*/
};

export default Item;
