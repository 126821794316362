import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Form, Alert } from 'react-bootstrap';
import axios from 'axios';
import envs from '../../../env';

export default function ChangePassword() {
  const history = useHistory();
  const [showAlertSuccess, setShowAlertSuccess] = React.useState(false);
  const [showAlertFailure, setShowAlertFailure] = React.useState(false);
  const [alertFailureText, setAlertFailureText] = React.useState('');

  const { email } = useParams();
  const { token } = useParams();
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');

  async function sendPasswordChangeConfirmation() {
    await axios
      .post(
        `${envs.url}password`,
        {
          Email: email,
          NewPassword: newPassword,
          ConfirmPassword: confirmPassword,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        console.log('password changed successfully');
        setShowAlertFailure(false);
        setShowAlertSuccess(true);
        setTimeout(() => history.push('/login'), 2000);
      })
      .catch((err) => {
        console.log('Password change error:', err.response.data.Message);
        setShowAlertSuccess(false);
        setAlertFailureText(err.response.data.Message);
        setShowAlertFailure(true);
      });
  }

  function formSubmit(e) {
    e.preventDefault();
    sendPasswordChangeConfirmation();
  }

  return (
    <div className="Login-wrapper">
      <div className="Login-content">
        <h3>Change Password</h3>
        {showAlertSuccess ? (
          <Alert variant={'success'}>Password changed successfully</Alert>
        ) : null}
        {showAlertFailure ? (
          <Alert variant={'danger'}>{alertFailureText}</Alert>
        ) : null}
        <p>Enter your new password and you'll be redirected back to login.</p>
        <Form onSubmit={formSubmit} style={{ width: '100%' }}>
          <Form.Control
            type="password"
            placeholder="new password"
            name="NewPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
          <Form.Control
            type="password"
            placeholder="confirm new password"
            name="ConfirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <Button className="mt-2" variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </div>
    </div>
  );
}
