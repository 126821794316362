let envs;

if (process.env.REACT_APP_CUSTOM_NODE_ENV === "prod") {
  envs = {
    url: "https://5m8w7babe3.execute-api.us-east-2.amazonaws.com/prod/",
    // sb_client_id:
    //   "AbTtyfFZDOAmaB7Gfat1iCvDZYz1g2lUI7zDUleqZXBW-zUWHE0zdumex5PdNMEK9Vp4y0WE01HS4JNv", // MFEA Live

    // sb_client_id:
    //   "ATKDN9WV37FInJZXEECYOgmy4XydUYNSB2PPyBNf6AiJAZgkkCN6MmeM7F_Is3v_Nt-ILRAcxPShaiVt", // Ping Live
    sb_client_id:
     "AdH9_PDz6fDE-3Ne1uDXxnciPqpT5E4Oa1hrWBSfjZGxiEtJtdeL69xuHmWWdJ5mTPnkcpMl3u5imlOs", // NAMI
  };
} else if (process.env.REACT_APP_CUSTOM_NODE_ENV === "beta") {
  envs = {
    url: "https://kgiv5yqa84.execute-api.us-east-2.amazonaws.com/beta/",
    sb_client_id:
      "ATKDN9WV37FInJZXEECYOgmy4XydUYNSB2PPyBNf6AiJAZgkkCN6MmeM7F_Is3v_Nt-ILRAcxPShaiVt", // Ping Live (real money)
    webhook: "6K7613599A049994R", // Ping Live (Not important)
  };
} else {
  envs = {
    url: "https://2491vsk8w1.execute-api.us-east-2.amazonaws.com/dev/",
    sb_client_id:
      "AXdKS8B0L25sYz0y1UQ2w0Sm7IMXqg44ZqxKdqPXjTqz6I-XSrwZaPbXSGCeY0BO15a2f1eYKvTh4UUP", // Ping DEV (fake money)
  };
}

module.exports = envs;
