import React, { useState, useContext, useEffect } from 'react';
import { Button, Form, Col, Row } from 'react-bootstrap';
import { UserContext } from '../../../UserContext';

import DateTimePicker from 'react-datetime-picker';
import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faImage,
  faCheckCircle,
  faFileArrowUp,
} from '@fortawesome/free-solid-svg-icons';

// custom components
import RAAToast from '../../Toast/Toast';

// redux | context
import { useDispatch, useSelector } from 'react-redux';
import {
  addImage,
  removeImage,
  uploadImage,
} from '../../../redux/actions/singleImageActions';

// styles
import './Create.css';
import {
  EventBannerContainerFullPreview,
  EventBannerContainerSplitPreview,
  EventBannerImageSplitPreview,
  EventBannerDescriptionSplitPreview,
} from '../../EventBanner/EventBannerStyles';
import {
  ImagePreview,
  DeleteButton,
  SuccessMark,
  UploadButton,
} from './ImageUploadStyles';
let envs = require('../../../env');

const CreateEvent = (props) => {
  const dispatch = useDispatch();
  const image = useSelector((state) => state.image);

  const [user, setUser] = useContext(UserContext);

  const [charities, setCharities] = useState([]);
  const [charityID, setCharityID] = useState(props.currChar.ID);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [bannerWidth, setBannerWidth] = useState('split');

  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const closeSuccessToast = () =>  setShowSuccessToast(false);
  const closeErrorToast = () => setShowErrorToast(false);

  useEffect(() => {
    getCharities();
    dispatch(removeImage());
  }, []);

  useEffect(() => {
    setCharityID(props.currChar.ID);
  }, [props, props.currChar]);

  const dateChangeHandler = {
    start: function (date) {
      setStartDate(date);
    },
    end: function (date) {
      setEndDate(date);
    },
  };

  const getCharitiesOwned = (charities, id) => {
    let p = [];
    charities.forEach((c) => {
      if (user.Role === 'Engineer') {
        p.push(c);
      }
      if (c.Roles && c.Roles.length) {
        c.Roles.forEach((r) => {
          if (r.AccountID === user.ID) p.push(c);
        });
      }
    });
    return p;
  };

  // GET CHARITIES OWNED BY USER
  const getCharities = () => {
    const authHead = `Bearer ${user.Token ? user.Token : ''}`;

    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: authHead,
      },
    };

    axios
      .get(`${envs.url}charities`, headers)
      .then((res) => {
        setCharities(getCharitiesOwned(res.data, user.ID));
      })
      .catch((err) => console.log(err.data));
    // setCharities(testC); // this requires static data to test
  };


  const saveEvent = (e) => {
    setShowErrorToast(false);
    e.preventDefault();

    // the image is waiting to be uploaded
    if (image.uploaded === false) {
      setShowErrorToast(true);
      setTimeout(() => setShowErrorToast(false), 2000);
      return false;
    }

    const authHead = `Bearer ${user.Token ? user.Token : ''}`;
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: authHead,
      },
    };
    const Link = `${envs.url}events`;

    const payload = {
      OwnerID: user.ID,
      CharityID: charityID,
      Name: e.target.eventName.value,
      Description: bannerWidth === 'full' ? '' : e.target.description.value,
      Images: [image.url],
      BuyItNowTimeout: 15,
      Public: 1,
      Properties: {
        Width: bannerWidth,
      },
      EventStart: startDate.toISOString(),
      EventEnd: endDate.toISOString(),
    };

    
    console.log(Link, payload, headers);
    axios
    .post(Link, payload, headers)
    .then((res) => {
      setShowSuccessToast(true);
      setTimeout(() => setShowSuccessToast(false), 2500);
      document.getElementsByName('eventName')[0].value = '';
      document.getElementById('description').value = '';
      dispatch(removeImage());
      })
      .catch((err) => console.log(err));
  };

  const handleChange = (ev) => {
    const { currentTarget } = ev;
    const currentImage = currentTarget.files[0];
    // this stops the app from breaking when a user presses "cancel" when selecting an image
    if (currentImage) dispatch(addImage(currentImage));
  };

  const uploadPressed = (ev) => {
    if (!image.uploaded) {
      return dispatch(uploadImage(ev, image.file));
    }
  };

  return (
    <div id="eventscreateform" className="createEvent cards">
      <RAAToast
        showToast={showSuccessToast}
        toggleShow={closeSuccessToast}
        title="Event Created"
        message="Event created successfully!"
        type="success"
      />
      <RAAToast
        showToast={showErrorToast}
        toggleShow={closeErrorToast}
        title="Upload Image Error"
        message="An image needs to be uploaded before submitting"
        type="error"
      />
      <Form onSubmit={saveEvent}>
        <Form.Group as={Row} controlId="formHorizontalEmail">
          <Form.Label
            style={{ marginRight: '0px', paddingRight: '0px' }}
            column
            sm={3}
          >
            Event Name:
          </Form.Label>
          <Col sm={9} style={{ marginLeft: '0px', paddingLeft: '0px' }}>
            <Form.Control
              required
              type="text"
              placeholder="Event Name"
              name="eventName"
            />
          </Col>
        </Form.Group>

        <Form.Group controlId="exampleForm.ControlTextarea1">
          <Form.Label>Event Description</Form.Label>
          <Form.Group controlId="bomboclaat">
            <Form.Check
              value="split"
              type="radio"
              id={`split-radio`}
              label="Description (Event banner must be in 400x400 format)"
              onChange={() => setBannerWidth('split')}
              checked={bannerWidth === 'split'}
            />
            <Form.Check
              value="full"
              type="radio"
              id={`full-radio`}
              label="No Description (Event banner must be in a 1600x400 format)"
              onChange={() => setBannerWidth('full')}
              checked={bannerWidth === 'full'}
            />
          </Form.Group>
          <Form.Control
            as="textarea"
            rows="3"
            id="description"
            name="description"
            placeholder="Event description..."
            disabled={bannerWidth === 'full'}
            required
          />
          <div
            style={{
              border: '1px solid black',
              background: 'gainsboro',
              padding: '1rem',
              marginTop: '1rem',
            }}
          >
            <h4 style={{ marginLeft: '10px' }}>Banner Preview</h4>
            {bannerWidth === 'full' ? (
              <EventBannerContainerFullPreview>
                <p style={{ color: '#fff', fontWeight: 'bold' }}>1600 x 400</p>
                <h1>
                  <FontAwesomeIcon icon={faImage} color="#fff" />
                </h1>
              </EventBannerContainerFullPreview>
            ) : (
              <EventBannerContainerSplitPreview>
                <EventBannerImageSplitPreview>
                  <h4 style={{ color: '#000' }}>400 x 400</h4>
                  <h1>
                    <FontAwesomeIcon icon={faImage} />
                  </h1>
                </EventBannerImageSplitPreview>
                <EventBannerDescriptionSplitPreview>
                  <h4 style={{ color: '#fff' }}>Title</h4>
                  <p style={{ color: '#fff' }}>Description...</p>
                </EventBannerDescriptionSplitPreview>
              </EventBannerContainerSplitPreview>
            )}
          </div>
        </Form.Group>

        <Form.Group>
          <Form.Label style={{ marginRight: '10px' }}>Start Date:</Form.Label>
          <DateTimePicker
            onChange={dateChangeHandler.start}
            value={startDate}
            name="startDate"
          />
        </Form.Group>
        <Form.Group>
          <Form.Label style={{ marginRight: '15px' }}>End Date:</Form.Label>
          <DateTimePicker
            onChange={dateChangeHandler.end}
            value={endDate}
            name="endDate"
          />
        </Form.Group>

        <Form.Group>
          <div className="App">
            <center>
              {image.success ? (
                <p style={{ color: 'green' }}>SUCCESSFUL UPLOAD</p>
              ) : image.uploading ? (
                <p>Image Uploading...</p>
              ) : (
                <p>Select an image for upload</p>
              )}
              <div className="input-and-images-container">
                <input
                  id={
                    image.file !== null
                      ? 'imageUploadInputDisabled'
                      : 'imageUploadInput'
                  }
                  type="file"
                  onChange={handleChange}
                  disabled={image.file !== null}
                  required
                />
                <label
                  for={
                    image.file !== null
                      ? 'imageUploadInputDisabled'
                      : 'imageUploadInput'
                  }
                >
                  <FontAwesomeIcon icon={faFileArrowUp} size="4x" />
                </label>
                {image.file !== null && (
                  <ImagePreview src={image.previewUrl}>
                    {image.url ? (
                      <SuccessMark onClick={() => dispatch(removeImage())}>
                        <FontAwesomeIcon icon={faCheckCircle} size="lg" />
                      </SuccessMark>
                    ) : (
                      <DeleteButton onClick={() => dispatch(removeImage())}>
                        X
                      </DeleteButton>
                    )}
                  </ImagePreview>
                )}
              </div>
              <br />
              <UploadButton
                onClick={uploadPressed}
                disabled={
                  image.file === null || // there is no image selected
                  (image.file !== null && image.uploaded === true) // there is an image selected and it has been uploaded
                }
              >
                UPLOAD
              </UploadButton>
            </center>
          </div>
        </Form.Group>

        <Form.Group as={Row}>
          <Col style={{ textAlign: 'center' }}>
            <Button type="submit">Submit Event!</Button>
          </Col>
        </Form.Group>
      </Form>
    </div>
  );
};

export default CreateEvent;
