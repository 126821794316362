import React, { useState, useCallback, useEffect } from 'react';
import useWindowSize from '../../hooks/useWindowSize';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';

// custom components
import EventCard from '../EventCard';
import { PrevButton, NextButton } from './EmblaCarouselButtons';

// styles
import { Embla, EmblaContainer, EmblaSlide } from './styles';

const EmblaCarousel = ({ array }) => {
  const windowSize = useWindowSize();
  const [viewportRef, embla] = useEmblaCarousel(
    {
      loop: false,
      dragFree: true,
      containScroll: 'trimSnaps',
    },
    [Autoplay()]
  );

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
  const onSelect = useCallback(() => {
    if (!embla) return;
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  useEffect(() => {
    if (!embla) return;
    embla.on('select', onSelect);
    onSelect();
  }, [embla, onSelect]);


  return (
    <Embla ref={viewportRef}>
      <EmblaContainer>
        {array.map((item) => (
          <EmblaSlide key={item.ID}>
            <EventCard event={item} />
          </EmblaSlide>
        ))}
      </EmblaContainer>
      {/* Remove buttons in <=Tablet views */}
      {windowSize.width > 900 && (
        <>
          <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
          <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
        </>
      )}
    </Embla>
  );
};

export default EmblaCarousel;
