import axios from "axios";
import envs from "../env";

const PingLog = async (opt) =>
    await axios
        .post(
            "https://wp3bkhlfcl.execute-api.us-east-2.amazonaws.com/beta/log",
            opt
        )
        .then((res) => console.log(res));

export default PingLog;
