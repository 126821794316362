import React, { useState, useContext } from 'react';
import { Link, withRouter, Redirect } from 'react-router-dom';
import { Form, Col, Button, InputGroup, FormControl, Alert } from 'react-bootstrap';
import axios from 'axios';

import { UserContext } from "../../UserContext";
import './Contact.css';
let envs = require('../../env');

const Contact = () => {
  const [user, setUser] = useContext(UserContext);  
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const authHead = `Bearer ${user.Token}`;

    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: authHead,
      },
    };

  const formSubmit = (e) => {
    e.preventDefault();
    const firstName = e.target.firstName.value;
    const lastName = e.target.lastName.value;
    const email = e.target.email.value;
    const cell = e.target.cell.value;
    const message = e.target.message.value;

    axios
      .post(
        `${envs.url}contact`,
        {
          AccountID: user.ID,
          ContactCell: cell,
          ContactEmail: email,
          ContactName: `${firstName} ${lastName}`,
          ContactMessage: message
        },
        headers
      )
      .then((res) => {
        console.log(res);
        setAlertMessage(res.data.Message);
        setShowAlert(true);
      })
      .catch((error) => {
        console.log(error)
        console.log(error.response.data.Message);
        setAlertMessage(error.response.data.Message);
        setShowAlert(true);
      })

  }

  return(
    <div className="Contact-wrapper">
      <div className="Contact-content">
        <h1>Contact Us</h1>
        {
          showAlert ? 
            <Alert variant={'success'}>
              {alertMessage}
            </Alert>
          :null
        }
        <Form onSubmit={formSubmit} className="signup-form">
          <Form.Row>
            <Col>
              <Form.Control
                type="text"
                name="firstName"
                placeholder="First Name"
                required
              />
            </Col>
            <Col>
              <Form.Control
                type="text"
                name="lastName"
                placeholder="Last Name"
                required
              />
            </Col>
          </Form.Row>
          <Form.Control
            type="email"
            placeholder="Email"
            name="email"
            required
          />
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>+1</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              id="formPhone"
              type="tel"
              name="cell"
              placeholder="3131234567"
              
            />
          </InputGroup>
          <Form.Group controlId="exampleForm.ControlTextarea1" className="mt-2">
            <Form.Label>Message</Form.Label>
            <Form.Control as="textarea" rows="5" name="message" style={{resize:"none"}} />
          </Form.Group>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default Contact;