import React from 'react';
import { Button, Form, Alert } from 'react-bootstrap';
import axios from 'axios';
import envs from '../../../env';

export default function ForgotPasswordForm() {
  const [showAlertSuccess, setShowAlertSuccess] = React.useState(false);
  const [showAlertFailure, setShowAlertFailure] = React.useState(false);
  const [email, setEmail] = React.useState('');

  async function sendPasswordResetEmail() {
    await axios
      .post(`${envs.url}startpassword`, { Email: email })
      .then((res) => {
        setShowAlertFailure(false);
        setShowAlertSuccess(true);
      })
      .catch((err) => {
        console.log('send password reset email error:', err)
        setShowAlertSuccess(false);
        setShowAlertFailure(true);
      });
  }

  function formSubmit(e) {
    e.preventDefault();
    sendPasswordResetEmail();
  }

  return (
    <div className="Login-wrapper">
      <div className="Login-content">
        <h3>Forgot Password Form</h3>
        {showAlertSuccess ? (
          <Alert variant={'success'}>Sent! Check your inbox.</Alert>
        ) : null}
        {showAlertFailure ? (
          <Alert variant={'danger'}>Email failed to send.</Alert>
        ) : null}
        <p>
          Enter your email and we'll provide you a link to change to your
          password.
        </p>
        <Form onSubmit={formSubmit} style={{ width: '100%' }}>
          <Form.Control
            type="email"
            placeholder="Email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <Button className="mt-2" variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </div>
    </div>
  );
}
